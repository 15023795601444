import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import { subscriptionData } from '../../../../core/data/json/subscriptionData';
import { apiRepository } from '../../../../api/apiRepository';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PreferredSection = () => {
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await apiRepository.getPackage();
        const filteredPackages = response.data.data.content.filter((pkg) => pkg.packageTypeId === 6);;
        setPackages(filteredPackages);
      } catch (error) {
        console.error("Error al obtener los paquetes:", error);
      }
    };

    fetchPackages();
  }, []);

  return (
    <section className="section pt-5 price-sections service-section">
      <div className="container col-11">
        <div className="row">
          <div className="col-md-12 text-center aos">
            <div className="section-heading sec-header">
              <h2>Entrena con Expertas</h2>
              <p>Entrenamiento especializado para tu salud y estética.</p>
            </div>
          </div>
        </div>
        {/* <div className="price-toggle mb-0 text-center">
          <div className="status-toggle toggle-pink d-inline-flex align-items-center">
            Bill Monthly
            <input type="checkbox" id="status_1" className="check" />
            <label htmlFor="status_1" className="checktoggle mx-3">
              checkbox
            </label>
            Bill Annualy
          </div>
        </div> */}
        <div className="row aos">
        {packages.map((suscrib: any, index: number) => (
          <div className="col-lg-4 col-sm-12" key={index}>
            <div className={`pricing-plans price-new ${index === 1 ? "active" : ""}`}>
              <div className="pricing-planshead">
                <h6>
                  S/ {suscrib.price}
                </h6>
                <h4>{suscrib.title}</h4>
                <h5>{suscrib.description}</h5>
              </div>
              <div className="pricing-planscontent">
                <ul>
                  {subscriptionData[0].features.map((feature: any, i: any) => (
                    <li key={i}>
                      <i className="fa fa-check-circle me-2" />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
                <div className="pricing-btn">
                  <Link to="#" className="btn btn-view">
                    Escoge tu Plan
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
        </div>
      </div>
    </section>
  )
}

export default PreferredSection