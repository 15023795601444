
import { Dropdown } from 'primereact/dropdown';
import { TagsInput } from 'react-tag-input-component';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import BreadCrumb from '../../common/breadcrumb/breadCrumb';
import CustomerSideBar from '../common/sidebar';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import React, { useState, useEffect } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import SchedulesModal from '../../common/modals/schedules-modal';
import DeleteSchedulesModal from '../../common/modals/delete-schedules-modal';
import { apiRepository, Schedules } from '../../../../api/apiRepository';


const CustomerProfile = () => {
  const [data, setData] = useState<Schedules[]>([]);
  const [selectedScheduleId, setSelectedScheduleId] = useState<number>(-1);
  const [selectedSchedules, setSelectedSchedules] = useState<string | null>(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(10);

  const fetchSchedules = async (pageNumber = 0, pageSize = 10) => {
    setLoading(true);
    try {
      const response = await apiRepository.getSchedules();
      setData(response.data.data);
      setTotalRecords(response.data.data.totalElements);
    } catch (error) {
      console.error('Error al obtener los datos de usuarios:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateSuccess = () => {
    fetchSchedules(page, rows);
  };

  useEffect(() => {
    fetchSchedules(page, rows);
  }, [page, rows]);

  const onPageChange = (e) => {
    setPage(e.page);
    setRows(e.rows);
    fetchSchedules(e.page, e.rows);
  };

  const formatTime12Hour = (timeString) => {
    const [hour, minute] = timeString.split(':');
    const date = new Date();
    date.setHours(hour, minute);
    return new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    }).format(date);
  };

  const formatReservation = (reservation: number) => {
    return reservation > 0 ? reservation : 'Sin reservas';
  };

  const actionButton = (rowData: any) => {
    const { id, title, date, hourStart, hourEnd, quantity,  reservation } = rowData;

    const handleEditClick = () => {
      setSelectedSchedules({id, title, date, hourStart, hourEnd, quantity,  reservation});
    };

    const handleDeleteClick = () => {
      setSelectedScheduleId(id);
    };

    return (
      <div className="table-actions d-flex">
        <Link
          className="delete-table me-2"
          to="#"
          onClick={handleEditClick}
          data-bs-toggle="modal"
          data-bs-target="#add-schedule"
        >
          <Icon.Edit className="react-feather-custom" />
        </Link>
        <Link
          className="delete-table"
          to="#"
          onClick={handleDeleteClick}
          data-bs-toggle="modal"
          data-bs-target="#delete-schedule"
        >
          <Icon.Trash2 className="react-feather-custom" />
        </Link>
      </div>
    );
  };
  return (
    <>
    <div className="page-wrapper">
      <div className="content">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-3 col-lg-4 ">
              <CustomerSideBar />
            </div>
            <div className="col-xl-9 col-lg-8">
            <div className="content-page-headersplit">
              <h4>Clases</h4>
              <div className="list-btn">
                <ul>
                  <li>
                    <button className="btn btn-primary-blue" type="button" data-bs-toggle="modal" data-bs-target="#add-schedule">
                      <i className="fa fa-plus me-2" />
                      Agregar Clase
                    </button>
                  </li>
                </ul>
              </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="table-resposnive table-div">
                    <table className="table datatable">
                      <DataTable
                        paginatorTemplate="RowsPerPageDropdown CurrentPageReport PrevPageLink PageLinks NextPageLink  "
                        currentPageReportTemplate="{first} to {last} of {totalRecords}"
                        value={data}
                        rows={rows}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        tableStyle={{ minWidth: '60rem'}}
                        totalRecords={totalRecords}
                        first={page * rows}
                        onPage={onPageChange}
                        showGridlines
                      >
                        <Column field="title" header="Nombre"></Column>
                        <Column field="quantity" header="Total Cupos"></Column>
                        <Column
                          field="reservation"
                          header="Reservas"
                          body={(rowData) => formatReservation(rowData.reservation)}
                        >
                        </Column>
                        <Column field="date" header="Dia"></Column>
                        <Column
                          field="hourStart"
                          header="Hora Inicio"
                          body={(rowData) => formatTime12Hour(rowData.hourStart)}
                        ></Column>
                        <Column
                          field="hourEnd"
                          header="Hora Final"
                          body={(rowData) => formatTime12Hour(rowData.hourEnd)}
                        ></Column>
                        <Column body={actionButton}></Column>
                      </DataTable>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SchedulesModal selectedSchedule={selectedSchedules} onUpdateSuccess={handleUpdateSuccess} />
      <DeleteSchedulesModal selectedScheduleId={selectedScheduleId} onDeleteSuccess={handleUpdateSuccess} />
    </div>
    </>
  );
};

export default CustomerProfile;
