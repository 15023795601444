import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd';
import { apiRepository, Banners } from '../../../api/apiRepository';

const AddHome = () => {
  const [services, setServices] = useState<Banners[]>([]);
  const [request, setRequest] = useState<Banners[]>([]);
  const [error, setError] = useState<string | null>(null);


  const fetchBanners = async () => {
    try {
      const response = await apiRepository.getBanners();
        const servicesWithStringIds = response.data.data.map((service) => ({
          ...service,
          id: service.id.toString(),
          image: service.image || '',
          status: service.status,
          upload: true,
        }));

      console.log(servicesWithStringIds);

      servicesWithStringIds.sort((a, b) => a.position - b.position);

      setRequest(servicesWithStringIds);
      setServices(servicesWithStringIds);
    } catch (error) {
      console.error("Error al obtener los datos de paquetes:", error);
    }
  };

  useEffect(() => {
    fetchBanners();
  }, []);


  const addNewServiceRow = () => {
    const maxId = services.length > 0 
    ? Math.max(...services.map(service => parseInt(service.id, 10))) 
    : 0;
    const newId = (maxId + 1).toString();
    console.debug(newId)
    setServices([
      ...services,
      { id: newId, title: '', subtitle: '', path: '', image: '', status: true, position: services.length + 1 },
    ]);
  };

  const deleteServiceRow = async (id: string) => {
    try {
      await apiRepository.deleteBanner(id);
    } catch (error) {
      console.error("Error al obtener los datos de paquetes:", error);
    }
    const updatedServices = services.filter((service) => service.id !== id);
    setServices(updatedServices);
  };

  const handleInputChange = (id: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const updatedServices = services.map((service) =>
      service.id === id ? { ...service, [name]: value } : service
    );
    setServices(updatedServices);
  };

  const handleCreateHome = async () => {
    const hasEmptyFields = services.some(
      (service) => !service.title || !service.subtitle || !service.path || !service.image,
    );

    if (hasEmptyFields) {
      setError('Por favor, completa todos los campos obligatorios.');
      return;
    }

    try {
      setError(null);
      for (const service of services) {
        const formData = new FormData();
        formData.append('title', service.title);
        formData.append('subtitle', service.subtitle);
        formData.append('path', service.path);
        formData.append('position', String(Number(service.position)));
        formData.append('status', String(service.status));
        if (service.image && service.image instanceof File) {
          formData.append('image', service.image);
        }

        if (!service.upload) {
          await apiRepository.createBanner(formData);
          console.debug('Banner creado:', service);
        } else {
          await apiRepository.updateBanner(service.id, formData);
          console.debug('Banner actualizado:', service);
        }
      }

      console.debug('Todos los banners procesados correctamente.');
      await fetchBanners();
    } catch (error) {
      console.error('Error al guardar los banners:', error);
    }
  };

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const reorderedServices = Array.from(services);
    const [movedService] = reorderedServices.splice(result.source.index, 1);
    reorderedServices.splice(result.destination.index, 0, movedService);

    setServices(reorderedServices);
    const updatedServicesWithPosition = reorderedServices.map((service, index) => ({
      ...service,
      position: index + 1,
    }));
  
    setServices(updatedServicesWithPosition);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
    if (event.target.files && event.target.files[0]) {
      const updatedServices = services.map((service) =>
        service.id === id ? { ...service, image: event.target.files[0] } : service  // Guardar el archivo real
      );
      setServices(updatedServices);
    }
  };

  const handleCheckboxChange = (id: string) => {
    const updatedServices = services.map((service) =>
      service.id === id ? { ...service, status: !service.status } : service
    );
    setServices(updatedServices);
  };

  return (
    <div className="page-wrapper custom-home">
      <div className="content">
        <div className="container-service col-lg-10 col-sm-12 m-auto">
          <div className="container-service">
            <div className="row">
              <div className="col-sm-12">
                <div className="additional">
                  <div className="sub-title Service">
                    <h6>Banner del Home</h6>
                  </div>
                </div>
              </div>
            </div>

            {error && <div className="alert alert-danger">{error}</div>}

            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="droppable-services">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {services.map((service, index) => (
                      <Draggable key={service.id} draggableId={service.id} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="row service-cont"
                            style={{
                              backgroundColor: snapshot.isDragging ? '#f9f9f9' : 'white',
                              padding: '10px',
                              borderRadius: '10px',
                              marginBottom: '10px',
                              ...provided.draggableProps.style,
                            }}
                          >
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>Titulo del Banner</label>
                              <input
                                type="text"
                                className="form-control"
                                name="title"
                                value={service.title}
                                onChange={(event) => handleInputChange(service.id, event)}
                              />
                            </div>
                            </div>
                            <div className="col-md-2">
                              <div className="form-group">
                                <label>Descripción del Banner</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="subtitle"
                                  value={service.subtitle}
                                  onChange={(event) => handleInputChange(service.id, event)}
                                />
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="form-group">
                                <label>
                                  Link del Banner
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="path"
                                  value={service.path}
                                  onChange={(event) => handleInputChange(service.id, event)}
                                />
                              </div>
                            </div>

                            <div className="col-md-2">
                              <div className="form-group">
                                <label>
                                    Imagen del Banner
                                </label>
                                <div className="profile-upload mb-3">
                                      <div className="profile-upload-img">
                                      {service.image && (
                                        <div className="d-flex">
                                          <img
                                            className="img-fluid shadow rounded"
                                            src={service.image instanceof File
                                              ? URL.createObjectURL(service.image)  // Si es un archivo, usar URL.createObjectURL
                                              : `http://95.217.211.66:8080${service.image}`} // Si no, asumir que es una URL
                                            alt="Vista previa"
                                            style={{ width: '100px', height: 'auto' }}
                                          />
                                        </div>
                                      )}
                                      </div>
                                      <div className="profile-upload-content">
                                          <div className="profile-upload-btn">
                                              <div className="profile-upload-file">
                                                <input
                                                  type="file"
                                                  accept="image/*"
                                                  onChange={(event) => handleFileChange(event, service.id)}
                                                />
                                                <Link to="#" className="btn btn-upload">
                                                    Subir
                                                </Link>
                                              </div>
                                          </div>
                                          {/* <div className="profile-upload-para">
                                              <p>
                                                  * Reconmendamos un temaño minimo de 320 x 320 pixeles. Se permiten
                                                  archivos .png y .jpg.
                                              </p>
                                          </div> */}
                                      </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-1">
                              <div className="form-group">
                                <label>
                                  Estado
                                </label>
                                <br/>
                                <div className="active-switch">
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    name="status"
                                    checked={service.status}
                                    onChange={() => handleCheckboxChange(service.id)}
                                />
                                <span className="sliders round" />
                                </label>
                                </div>
                              </div>
                            </div>
                            {services.length > 1 && (
                              <div className="col-md-1">
                                <button
                                  onClick={() => deleteServiceRow(service.id)}
                                  className="btn btn-danger-outline delete-icon"
                                >
                                  <Icon.Trash2 className="react-feather-custom trashicon" />
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <div className="row">
              <div className="col-6 d-flex justify-content-start">
                <Link
                  to="#"
                  className="link-sets add-extra"
                  onClick={addNewServiceRow}
                >
                  <i className="fa fa-plus-circle me-2" aria-hidden="true" />
                  Add Additional Service
                </Link>
              </div>

              <div className="col-6 d-flex justify-content-end">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={handleCreateHome}
                >
                  Guardar Home
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddHome;