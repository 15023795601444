import React, { useState, useEffect } from 'react'
import * as Icon from 'react-feather';
import { validateUser, ValidationError } from '../../../../core/form/validationForm';
import { apiRepository, Users } from '../../../../api/apiRepository';
import { InputField, CalendarField, DropdownField } from '../input/inputField';


export interface UpdateUserModalProps {
    selectedUser?: { id: number; name: string; lastname: string;  email: string; password: string; profile: string; phone: string; active: boolean; dni: string; birthdate: string; };
    onUpdateSuccess?: () => void;
}

const UsersModal: React.FC<UpdateUserModalProps> = ({ selectedUser, onUpdateSuccess } ) => {
    const [id, setId] = useState<number>(selectedUser ? selectedUser.id : -1);
    const [name, setName] = useState<string>(selectedUser ? selectedUser.name : '');
    const [lastname, setLastname] = useState<string>(selectedUser ? selectedUser.lastname : '');
    const [email, setEmail] = useState<string>(selectedUser ? selectedUser.email : '');
    const [password, setPassword] = useState<string>(selectedUser ? selectedUser.password : '');
    const [profile, setProfile] = useState<string>(selectedUser ? selectedUser.profile : '');
    const [phone, setPhone] = useState<string>(selectedUser ? selectedUser.phone : '');
    const [dni, setDni] = useState<string>(selectedUser ? selectedUser.dni : '');
    const [birthdate, setBirthdate] = useState<Date | null>(selectedUser ? new Date(selectedUser.birthdate) : null);
    const [active, setActive] = useState<boolean>(selectedUser ? selectedUser.active : true);
    const [errors, setErrors] = useState<ValidationError[]>([]);
    const [isValidated, setIsValidated] = useState(false);
    const [newPassword, setNewPassword] = useState<string>('');

    const roles = [{label: 'Customer', value: 'customer'}, {label: 'Trainer', value: 'trainer'}, {label: 'Admin', value: 'admin'} ];

    useEffect(() => {
        if (selectedUser) {
            setId(selectedUser.id);
            setName(selectedUser.name);
            setLastname(selectedUser.lastname);
            setEmail(selectedUser.email);
            setPassword(selectedUser.password);
            setProfile(selectedUser.profile);
            setPhone(selectedUser.phone);
            setDni(selectedUser.dni);
            setBirthdate(new Date(selectedUser.birthdate));
            setActive(selectedUser.active);
        }
    }, [selectedUser]);

    useEffect(() => {
        const user: Users = { name, lastname, email, phone, password, profile, active, birthdate, dni };
        const validationErrors = validateUser(user);
        setIsValidated(validationErrors.length === 0 && password === newPassword);
    }, [name, lastname, email, password, profile, active, phone, birthdate, dni, newPassword]);

    const resetFields = () => {
        setId(-1);
        setName('');
        setLastname('');
        setActive(true);
        setEmail('');
        setPassword('');
        setNewPassword('');
        setProfile('');
        setBirthdate(null);
        setPhone('');
        setDni('');
        setActive(true);
        setErrors([]);
        setIsValidated(false);
    };

    useEffect(() => {
        const addModalElement = document.getElementById('add-user');
        const editModalElement = document.getElementById('edit-user');

        addModalElement?.addEventListener('hide.bs.modal', resetFields);
        editModalElement?.addEventListener('hide.bs.modal', resetFields);

        return () => {
            addModalElement?.removeEventListener('hide.bs.modal', resetFields);
            editModalElement?.removeEventListener('hide.bs.modal', resetFields);
        };
    }, []);

    const handleConfirmCreate = async (event: React.FormEvent) => {
        event.preventDefault();
        const user: Users = { name, lastname, email, phone, password, profile, active, birthdate, dni };
        const validationErrors = validateUser(user);
        if (password !== newPassword) {
            validationErrors.push({ field: "newPassword", message: "Las contraseñas deben ser iguales" });
        }

        if (validationErrors.length > 0) {
            setErrors(validationErrors);
            return;
        } else {
            setErrors([]);
        }

        const newUser: Users = { id, name, lastname, email, phone, password, profile, active,  birthdate, dni};

        try {
            if (id === -1) {
                await apiRepository.createUser(newUser);
            } else {
                await apiRepository.updateUser(id, newUser);
            }
            if (onUpdateSuccess) onUpdateSuccess();
        } catch (error) {
            console.error("Error al guardar el usuario:", error);
        }
    };

    const getErrorForField = (field:any) => {
        const error = errors.find((error) => error.field === field);
        return error ? error.message : null;
    };

    return (
        <>
            {/* Add User */}
            <div className="modal fade" id="add-user">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Agregar Usuario</h5>
                            <button
                                type="button"
                                className="btn-close close-modal"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <Icon.X className="react-feather-custom"></Icon.X>
                            </button>
                        </div>
                        <div className="modal-body pt-0">
                            <form onSubmit={ handleConfirmCreate }>
                                {/* <div className="profile-upload mb-3">
                                    <div className="profile-upload-img">
                                        <ImageWithBasePath
                                            src="assets/admin/img/customer/user-01.jpg"
                                            alt="img"
                                            id="blah"
                                        />
                                    </div>
                                    <div className="profile-upload-content">
                                        <div className="profile-upload-btn">
                                            <div className="profile-upload-file">
                                                <input type="file" id="imgInp" />
                                                <Link to="#" className="btn btn-upload">
                                                    Subir
                                                </Link>
                                            </div>
                                            <Link to="#" className="btn btn-remove">
                                                Remove
                                            </Link>
                                        </div>
                                        <div className="profile-upload-para">
                                            <p>
                                                * Reconmendamos un temaño minimo de 320 x 320 pixeles. Se permiten
                                                archivos .png y .jpg.
                                            </p>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="row">
                                    <div className="col-md-6">
                                        <InputField
                                            label="Nombre"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            error={getErrorForField('name')}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <InputField
                                            label="Apellido"
                                            value={lastname}
                                            onChange={(e) => setLastname(e.target.value)}
                                            error={getErrorForField('lastname')}
                                        />
                                    </div>
                                </div>
                                <InputField
                                    label="Correo"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    error={getErrorForField('email')}
                                />
                                <CalendarField
                                    label="Fecha de nacimiento"
                                    value={birthdate}
                                    onChange={(e) => setBirthdate(e.value as Date)}
                                    error={getErrorForField('birthdate')}
                                />
                                <div className="row">
                                    <div className="col-md-6">
                                    <InputField
                                    label="Telefono"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    error={getErrorForField('phone')}
                                    />
                                    </div>
                                    <div className="col-md-6">
                                        <InputField
                                        label="DNI"
                                        value={dni}
                                        onChange={(e) => setDni(e.target.value)}
                                        error={getErrorForField('dni')}
                                    />
                                    </div>
                                </div>
                                <InputField
                                    type='password'
                                    label="Nueva Contraseña"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    error={getErrorForField('password')}
                                />
                                <InputField
                                    type='password'
                                    label="Confirmar Contraseña"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    error={getErrorForField('newPassword')}
                                />
                                <DropdownField
                                    label="Rol"
                                    value={profile}
                                    onChange={(e) => setProfile(e.value)}
                                    options={roles}
                                    error={getErrorForField('profile')}
                                />
                                <div className="text-end">
                                    <button type="button" className="btn btn-secondary me-2" data-bs-dismiss="modal">
                                        Cancelar
                                    </button>
                                    <button type="submit" className="btn btn-primary" data-bs-dismiss={isValidated ? "modal" : undefined}>
                                        Crear
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Add User */}
            {/* Edit User */}
            <div className="modal fade" id="edit-user">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Editar Usuario</h5>
                            <button
                                type="button"
                                className="btn-close close-modal"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <Icon.X className="react-feather-custom"></Icon.X>
                            </button>
                        </div>
                        <div className="modal-body pt-0">
                            <form  onSubmit={handleConfirmCreate}>
                            <div className="row">
                                    <div className="col-md-6">
                                        <InputField
                                            label="Nombre"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            error={getErrorForField('name')}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <InputField
                                            label="Apellido"
                                            value={lastname}
                                            onChange={(e) => setLastname(e.target.value)}
                                            error={getErrorForField('lastname')}
                                        />
                                    </div>
                                </div>
                                <InputField
                                    label="Correo"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    error={getErrorForField('email')}
                                />
                                <CalendarField
                                    label="Fecha de nacimiento"
                                    value={birthdate}
                                    onChange={(e) => setBirthdate(e.value as Date)}
                                    error={getErrorForField('birthdate')}
                                />
                                <div className="row">
                                    <div className="col-md-6">
                                    <InputField
                                    label="Telefono"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    error={getErrorForField('phone')}
                                    />
                                    </div>
                                    <div className="col-md-6">
                                        <InputField
                                        label="DNI"
                                        value={dni}
                                        onChange={(e) => setDni(e.target.value)}
                                        error={getErrorForField('dni')}
                                    />
                                    </div>
                                </div>
                                <InputField
                                    type='password'
                                    label="Nueva Contraseña"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    error={getErrorForField('password')}
                                />
                                <InputField
                                    type='password'
                                    label="Confirmar Contraseña"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    error={getErrorForField('newPassword')}
                                />
                                <DropdownField
                                    label="Rol"
                                    value={profile}
                                    onChange={(e) => setProfile(e.value)}
                                    options={roles}
                                    error={getErrorForField('profile')}
                                />
                                <div className="form-groupheads d-flex d-flex justify-content-between mb-4">
                                    <h2>Estado</h2>
                                    <div className="active-switch">
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                checked={active}
                                                onChange={(e) => setActive(e.target.checked)}
                                                defaultChecked={true} />
                                            <span className="sliders round" />
                                        </label>
                                    </div>
                                </div>
                                <div className="text-end">
                                    <button
                                        type="button"
                                        className="btn btn-secondary me-2"
                                        data-bs-dismiss="modal"
                                    >
                                        Cancelar
                                    </button>
                                    <button type="submit" className="btn btn-primary" data-bs-dismiss={isValidated ? "modal" : undefined}>
                                        Guardar Cambios
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Edit User */}
        </>
    )
}

export default UsersModal
