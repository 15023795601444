export const serviceSlideData = [
  {
    image: 'strength/strength-1.jpg',
    Slider: [
      { id: 1, Image: 'strength/strength-1.jpg' },
      { id: 2, Image: 'strength/strength-3.jpg' },
      { id: 3, Image: 'strength/strength-4.jpg' },
    ],
    TradeIcon: false,
    Title: 'Strength',
    Text: 'Esculpe tu cuerpo, potencia tu fuerza y mejora tu técnica al levantar pesas bajo la cuidadosa instrucción de la entrenadora.',
    Rating: '4.5',
  },
  {
    id: 2,
    image: 'pilates/pilates-1.jpg',

    Slider: [
      { id: 1, Image: 'pilates/pilates-1.jpg' },
      { id: 2, Image: 'pilates/pilates-2.jpg' },
      { id: 3, Image: 'pilates/pilates-3.jpg' },
    ],
    TradeIcon: false,
    Title: 'Power Pilates',
    Text: 'Aumenta tu resistencia muscular y fortalece tu core mientras generas una profunda conexión cuerpo-mente.',
    Rating: '4.4',
  },
  {
    id: 3,
    image: 'hiit/hiit-1.jpg',

    Slider: [
      { id: 1, Image: 'hiit/hiit-1.jpg' },
      { id: 2, Image: 'hiit/hiit-2.jpg' },
      { id: 3, Image: 'hiit/hiit-3.jpg' },
    ],
    TradeIcon: false,
    Title: 'Hiit Burner',
    Text: 'Eleva tu rendimiento al máximo con un entrenamiento de intervalos de alta intensidad que combina un desafío cardiovascular con resistencia muscular.',
    Rating: '4.7',
  },
];
