import React from 'react';
import { Link } from 'react-router-dom';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';

const FooterOne = () => {
  const routes = all_routes

  return (
    <footer className="footer footer-second ">
      {/* Footer Top */}
      <div className="footer-top" >
        <div className="container col-11">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              {/* Footer Widget */}
              <div className="footer-widget">
                <div className="footer-logo">
                  <Link to={routes.index}>
                    <ImageWithBasePath src="assets/img/logo-lift.svg" alt="logo" />
                  </Link>
                </div>
                <div className="footer-content">
                  <div>
                    <p className="fs-16">
                      <span className="ti ti-device-mobile me-2" />
                      934 - 345 - 399
                    </p>
                  </div>
                  <div>
                    <p className="fs-16">
                      <span className="ti ti-device-mobile me-2" />
                      info@example.com
                    </p>
                  </div>
                </div>

              </div>
              {/* /Footer Widget */}
            </div>
            <div className="col-lg-2 col-md-4">
              {/* Footer Widget */}
              <div className="footer-widget footer-menus">
                <h2 className="footer-title">Paginas</h2>
                <ul>
                  <li>
                    <Link to={routes.index}>Home</Link>
                  </li>
                  <li>
                    <Link to={routes.categories}>Todas los servicios</Link>
                  </li>
                  <li>
                    <Link to={routes.contactUs}>Contactanos</Link>
                  </li>
                </ul>
              </div>
              {/* /Footer Widget */}
            </div>
            <div className="col-lg-2 col-md-4">
              {/* Footer Widget */}
              <div className="footer-widget footer-menus">
                <h2 className="footer-title">Ayuda</h2>
                <ul>
                  <li>
                    <Link to={routes.faq}>FAQS</Link>
                  </li>
                  <li>
                    <Link to={routes.contactUs}>Libro de reclamaciones</Link>
                    </li>
                </ul>
              </div>
              {/* /Footer Widget */}
            </div>
            <div className="col-lg-2 col-md-4">
              {/* Footer Widget */}
              <div className="footer-widget footer-menus">
              <h2 className="footer-title">Follow Us</h2>
                <div className="social-icon">
                  <ul className="mb-0">
                    <li>
                      <Link to="#">
                        <i className="fa-brands fa-facebook" />{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fab fa-twitter" />{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fa-brands fa-instagram" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fa-brands fa-linkedin" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              {/* /Footer Widget */}
            </div>
            <div className="col-lg-3 col-md-6">
              {/* Footer Widget */}
              <div className="footer-widget">
                <h2 className="footer-subtitle">Suscribete</h2>
                <div className="subscribe-form mb-4">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter Email Address"
                  />
                  <button type="submit" className="btn footer-btn">
                    <i className="feather-send" />
                  </button>
                </div>
              </div>
              {/* /Footer Widget */}
            </div>
          </div>
        </div>
      </div>
      {/* /Footer Top */}
      {/* Footer Bottom */}
      <div className="footer-bottoms">
        <div className="container col-11">
          {/* Copyright */}
          <div className="copyright">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-6">
                <div className="copyright-text">
                  <p className="mb-0 fs-14">
                    © 2024 Lift. Todos los derechos reservados.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                {/* Copyright Menu */}
                <div className="copyright-menu">
                  <ul className="policy-menu">
                    <li>
                      <Link to={routes.privacyPolicy}>Politica de Privacidad</Link>
                    </li>
                    <li>
                      <Link to={routes.termsCondition}>Terminos y Condiciones</Link>
                    </li>
                  </ul>
                </div>
                {/* /Copyright Menu */}
              </div>
            </div>
          </div>
          {/* /Copyright */}
        </div>
      </div>
      {/* /Footer Bottom */}
    </footer>

  );
};

export default FooterOne;
