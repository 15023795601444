import React, { useState } from 'react';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import TimePicker from 'react-time-picker';

type FieldProps = {
    label?: string;
    error?: string | null;
};

type InputFieldProps = FieldProps & {
    value: string;
    type?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void | null;
    name?: string;
};

type CalendarFieldProps = FieldProps & {
    value: Date;
    dateFormat?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void | null;
};

type DropdownFieldProps = FieldProps & {
    value: any;
    options: { label: string; value: any }[];
    onChange?: (event: { value: any }) => void;
    optionLabel?: string;
};

type TimePickerFieldProps = {
    label?: string;
    value: string | undefined;
    onChange?: (value: moment.Moment | null) => void;
    error?: string | null;
    name?: string;
    format?: string;
};

export const InputField: React.FC<InputFieldProps> = ({ label, value, onChange, error, name, type = 'text' }) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(prevState => !prevState);
    };

    return (
        <div className="mb-3">
            {label && <label className="form-label">{label}</label>}
            {type === "password" ? (
                <div className="pass-group">
                    <input
                        type={isPasswordVisible ? 'text' : 'password'}
                        className="form-control pass-input"
                        value={value}
                        name={name}
                        onChange={onChange}
                    />
                    <span
                        className={`fas toggle-password ${isPasswordVisible ? 'fa-eye' : 'fa-eye-slash'}`}
                        onClick={togglePasswordVisibility}
                    />
                </div>
            ) : (
                <input
                    type={type}
                    className="form-control"
                    value={value}
                    name={name}
                    onChange={onChange}
                />
            )}
            {error && <small className="text-danger">{error}</small>}
        </div>
    );
};

export const CalendarField: React.FC<CalendarFieldProps> = ({ label, value, onChange, error, dateFormat = "dd/mm/yy" }) => {
    return (
        <div className="mb-3">
            {label && <label className="form-label">{label}</label>}
            <Calendar
                value={value}
                onChange={onChange}
                dateFormat={dateFormat}
                showIcon
            />
            {error && <small className="text-danger">{error}</small>}
        </div>
    );
};

export const DropdownField: React.FC<DropdownFieldProps> = ({ label, value, options, onChange, error, optionLabel = "label" }) => {
    return (
        <div className="mb-3">
            {label && <label className="form-label">{label}</label>}
            <Dropdown
                value={value}
                onChange={onChange}
                options={options}
                optionLabel={optionLabel}
                className="select w-100"
            />
            {error && <small className="text-danger">{error}</small>}
        </div>
    );
};

export const TimePickerField: React.FC<TimePickerFieldProps> = ({ label, value, onChange, error, format = "h:mm A" }) => {
    return (
        <div className="mb-3">
            {label && <label className="form-label">{label}</label>}
            <TimePicker
                className="timepicker input-group-text form-control custom-timepicker"
                name={name}
                onChange={onChange}
                bordered={false}
                value={value}
                format={format}
                disableClock={true}
                clearable={true}
                />
            {error && <small className="text-danger">{error}</small>}
        </div>
    );
};