import React, { useEffect, useState } from 'react'
import { all_routes } from '../../../../core/data/routes/all_routes';
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { serviceSlideData } from '../../../../core/data/json/services-slide';
import { apiRepository } from '../../../../api/apiRepository';
import Slider from 'react-slick';

const FeatureSection = () => {
  const routes = all_routes;
  const imgslideroption = {
      dots: false,
      nav: false,
      infinite: true,
      speed: 500,
      swipe:true,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 550,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 0,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };
    const slideslideroption = {
      dots: true,
      nav: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      draggable:true,
      tochMove:true,
      swipe:true,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 550,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 0,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

  const [packages, setPackages] = useState([]);

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await apiRepository.getClasses();
        setPackages(response.data.data);
      } catch (error) {
        console.error("Error al obtener los paquetes:", error);
      }
    };

    fetchPackages();
  }, []);

  return (
    <>
  {/* Service Section */}
  <section className="section">
    <div className="container col-11">
      <div className="row justify-content-center">
        <div
          className="col-lg-6 text-center wow fadeInUp"
          data-wow-delay="0.2s"
        >
          <div className="section-header text-center">
            <h2 className="mb-1">
              Nuestras <span className="text-linear-primary">Sesiones Destacadas</span>
            </h2>
            <p className="sub-title">
            Cada sesión está diseñada para maximizar tu potencial físico y apoyar tus objetivos de estética y salud.
            </p>
          </div>
        </div>
      </div>
      <Slider {...imgslideroption} className="service-slider owl-carousel nav-center">
        {packages.map((res: any, index: number) => {
          const slideData = serviceSlideData[index];
          if (!slideData) {
            console.warn(`No slide data found for index ${index}`);
            return null; // O muestra un placeholder
          }
          return (
            <div className="service-item wow fadeInUp" data-wow-delay="0.2s" key={index}>
              <div className="service-img">
                <div className="slide-images">
                  <Link to={routes.serviceDetails1}>
                    <ImageWithBasePath
                      src={`assets/img/lift/categorias/${slideData.image}`}
                      className="img-fluid"
                      alt="img"
                    />
                  </Link>
                </div>
                <div className="fav-item">
                  <Link to="#" className="fav-icon">
                    <i className="ti ti-heart" />
                  </Link>
                </div>
              </div>
              <div className="service-content">
                <div className="d-flex justify-content-between">
                  <h6 className="text-truncate mb-1">
                    <Link to={routes.serviceDetails1}>{res.title}</Link>
                  </h6>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <p className="fw-medium fs-14 mb-0">{res.description}</p>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
      {serviceSlideData.length > 3 && (
          <div className="row">
            <div className="col-md-12">
              <div className="text-center wow fadeInUp" data-wow-delay="0.2s">
                <Link to={routes.search} className="btn btn-dark">
                  View All
                  <i className="ti ti-arrow-right ms-2" />
                </Link>
              </div>
            </div>
          </div>
        )}
    </div>
  </section>
  {/* /Service Section */}
</>

  )
}

export default FeatureSection