import React, { useState, useEffect } from 'react';
import * as Icon from 'react-feather';
import { Calendar } from 'primereact/calendar';
import { apiRepository, Coupon } from '../../../../api/apiRepository';
import { validateCoupon, ValidationError } from '../../../../core/form/validationForm';

export interface UpdateCouponModalProps {
    selectedCoupon: { id: number; name: string; code: string; descount: string; status: boolean; dateExpired: string };
    onUpdateSuccess?: () => void;
}

const CouponesModal: React.FC<UpdateCouponModalProps> = ({ selectedCoupon, onUpdateSuccess }) => {
    const [id, setId] = useState<number>(selectedCoupon ? selectedCoupon.id : -1);
    const [name, setName] = useState<string>(selectedCoupon ? selectedCoupon.name : '');
    const [code, setCode] = useState<string>(selectedCoupon ? selectedCoupon.code : '');
    const [descount, setDescount] = useState<string>(selectedCoupon ? selectedCoupon.descount : '');
    const [dateExpired, setDateExpired] = useState<Date | null>(selectedCoupon ? new Date(selectedCoupon.dateExpired) : null);
    const [status, setStatus] = useState<boolean>(selectedCoupon ? selectedCoupon.status : true);
    const [errors, setErrors] = useState<ValidationError[]>([]);
    const [isValidated, setIsValidated] = useState(false);

    useEffect(() => {
        if (selectedCoupon) {
            setId(selectedCoupon.id);
            setName(selectedCoupon.name);
            setCode(selectedCoupon.code);
            setDescount(selectedCoupon.descount);
            setDateExpired(new Date(selectedCoupon.dateExpired));
            setStatus(selectedCoupon.status);
        }
    }, [selectedCoupon]);

    useEffect(() => {
        const validationErrors = validateCoupon(name, code, dateExpired);
        setIsValidated(validationErrors.length === 0);
    }, [name, code, dateExpired]);

    const resetFields = () => {
        setId(-1);
        setName('');
        setCode('');
        setDescount('');
        setDateExpired(null);
        setStatus(true);
        setErrors([]);
        setIsValidated(false);
    };

    useEffect(() => {
        const addModalElement = document.getElementById('add-coupon');
        const editModalElement = document.getElementById('edit-coupon');

        addModalElement?.addEventListener('hide.bs.modal', resetFields);
        editModalElement?.addEventListener('hide.bs.modal', resetFields);

        return () => {
            addModalElement?.removeEventListener('hide.bs.modal', resetFields);
            editModalElement?.removeEventListener('hide.bs.modal', resetFields);
        };
    }, []);

    const handleConfirmCreate = async (event: React.FormEvent) => {
        event.preventDefault();

        const validationErrors = validateCoupon(name, code, dateExpired,);
        if (validationErrors.length > 0) {
            setErrors(validationErrors);
            return;
        } else {
            setErrors([]);
        }
        const newCoupon: Coupon = { id, name, code, descount, status, dateExpired: dateExpired ? dateExpired.toISOString() : '' };

        try {
            if (id === -1) {
                await apiRepository.createCoupon(newCoupon);
            } else {
                await apiRepository.updateCoupon(id, newCoupon);
            }
            if (onUpdateSuccess) onUpdateSuccess();
        } catch (error) {
            console.error('Error al crear o actualizar el cupón:', error);
        }
    };

    return (
        <>
            {/* Add Coupon */}
            <div className="modal fade" id="add-coupon">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Agregar Cupón</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Cerrar">
                            </button>
                        </div>
                        <div className="modal-body pt-0">
                            <form onSubmit={handleConfirmCreate}>
                                <div className="mb-3">
                                    <label className="form-label">Nombre</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Nombre"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                    {errors.find(error => error.field === 'name') && (
                                        <small className="text-danger">
                                            {errors.find(error => error.field === 'name')?.message}
                                        </small>
                                    )}
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Cupón</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Cupón"
                                        value={code}
                                        onChange={(e) => setCode(e.target.value)}
                                    />
                                    {errors.find(error => error.field === 'code') && (
                                        <small className="text-danger">
                                            {errors.find(error => error.field === 'code')?.message}
                                        </small>
                                    )}
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Descuento</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Descuento"
                                        value={descount}
                                        onChange={(e) => setDescount(e.target.value)}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Fecha de Expiración</label>
                                    <Calendar
                                        value={dateExpired}
                                        onChange={(e) => setDateExpired(e.value as Date)}
                                        dateFormat="dd/mm/yy"
                                        showIcon
                                    />
                                    {errors.find(error => error.field === 'dateExpired') && (
                                        <small className="text-danger">
                                            {errors.find(error => error.field === 'dateExpired')?.message}
                                        </small>
                                    )}
                                </div>
                                <div className="form-group d-flex justify-content-between mb-4">
                                    <h2>Estado</h2>
                                    <div className="active-switch d-flex align-items-center">
                                        <h6>Habilitar</h6>
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                checked={status}
                                                onChange={(e) => setStatus(e.target.checked)}
                                            />
                                            <span className="sliders round" />
                                        </label>
                                    </div>
                                </div>
                                <div className="text-end">
                                    <button type="button" className="btn btn-secondary me-2" data-bs-dismiss="modal">
                                        Cancelar
                                    </button>
                                    <button type="submit" className="btn btn-primary" data-bs-dismiss={isValidated ? 'modal' : undefined}>
                                        Agregar Cupon
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* Edit Coupon */}
            <div className="modal fade" id="edit-coupon">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Editar Cupón</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Cerrar">
                            </button>
                        </div>
                        <div className="modal-body pt-0">
                            <form onSubmit={handleConfirmCreate}>
                                <div className="mb-3">
                                    <label className="form-label">Nombre</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Nombre"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                    {errors.find(error => error.field === 'name') && (
                                        <small className="text-danger">
                                            {errors.find(error => error.field === 'name')?.message}
                                        </small>
                                    )}
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Cupón</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Cupón"
                                        value={code}
                                        onChange={(e) => setCode(e.target.value)}
                                    />
                                    {errors.find(error => error.field === 'code') && (
                                        <small className="text-danger">
                                            {errors.find(error => error.field === 'code')?.message}
                                        </small>
                                    )}
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Descuento</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Descuento"
                                        value={descount}
                                        onChange={(e) => setDescount(e.target.value)}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Fecha de Expiración</label>
                                    <Calendar
                                        value={dateExpired}
                                        onChange={(e) => setDateExpired(e.value as Date)}
                                        dateFormat="dd/mm/yy"
                                        showIcon
                                    />
                                    {errors.find(error => error.field === 'dateExpired') && (
                                        <small className="text-danger">
                                            {errors.find(error => error.field === 'dateExpired')?.message}
                                        </small>
                                    )}
                                </div>
                                <div className="form-group d-flex justify-content-between mb-4">
                                    <h2>Estado</h2>
                                    <div className="active-switch d-flex align-items-center">
                                        <h6>Habilitar</h6>
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                checked={status}
                                                onChange={(e) => setStatus(e.target.checked)}
                                            />
                                            <span className="sliders round" />
                                        </label>
                                    </div>
                                </div>
                                <div className="text-end">
                                    <button type="button" className="btn btn-secondary me-2" data-bs-dismiss="modal">
                                        Cancelar
                                    </button>
                                    <button type="submit" className="btn btn-primary" data-bs-dismiss={isValidated ? 'modal' : undefined}>
                                        Guardar
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CouponesModal;
