import React, { useState, useEffect  }  from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import FaqModal from '../common/modals/faq-modal';
import DeleteFaqModal from '../common/modals/delete-faq-modal';
import { FaqItem } from '../../../core/models/interface';
import { apiRepository } from '../../../api/apiRepository';

const Faq = () => {
  const [selectedId, setSelectedId] = useState<number>(-1);
  const [selectedFaq, setSelectedFaq] = useState<string |  null>(null);
  const [data, setData] = useState<FaqItem[]>([]);

  const fetchFaqs = async () => {
    try {
      const response = await apiRepository.getFaqs();
      setData(response.data.data);
    } catch (error) {
      console.error("Error al obtener los datos de FAQs:", error);
    }
  };

  useEffect(() => {
    fetchFaqs();
  }, []);

  const handleUpdateSuccess = () => {
    fetchFaqs();
  };

  const actionButton = (rowData:any) => {
    const { id, title, description, status } = rowData;

    const handleDeleteClick = () => {
      setSelectedFaq({id, title, status, description});
      setSelectedId(id);
    };
    return (
      <div className="table-actions d-flex">
        <Link
          className="delete-table me-2"
          onClick={handleDeleteClick}
          to="#"
          data-bs-toggle="modal"
          data-bs-target="#edit-faq"
        >
          <Icon.Edit className="react-feather-custom" />
        </Link>
        <Link
          className="delete-table border-none"
          onClick={handleDeleteClick}
          to="#"
          data-bs-toggle="modal"
          data-bs-target="#delete-faq"
        >
          <Icon.Trash2 className="react-feather-custom" />
        </Link>
      </div>
    );
  };

  return (
    <div className="page-wrapper page-settings">
      <div className="content">
        <div className="content-page-header content-page-headersplit">
          <h5>FAQ’s</h5>
          <div className="list-btn">
            <ul>
              <li>
                <button
                  className="btn btn-primary"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#add-faq"
                >
                  <i className="fa fa-plus me-2" />
                  Add FAQ
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="table-resposnive">
              <DataTable
                value={data}
                showGridlines
                tableStyle={{ minWidth: '50rem' }}
              >
                <Column sortable field="id" header="#"></Column>
                <Column sortable field="title" header="Title"></Column>
                <Column
                  sortable
                  field="action"
                  header="Action"
                  body={actionButton}
                ></Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
      <FaqModal selectedFaq={selectedFaq} onUpdateSuccess={handleUpdateSuccess}/>
      <DeleteFaqModal selectedId={selectedId}  onDeleteSuccess={handleUpdateSuccess}/>
    </div>
  );
};

export default Faq;