import { Users, Schedules } from '../../api/apiRepository'
import moment from 'moment';

export interface ValidationError {
    field: string;
    message: string;
}

export const validateLogin = (email: string, password: string): ValidationError[] => {
    const errors: ValidationError[] = [];

    if (!email || !email.trim()) {
      errors.push({ field: 'email', message: 'El correo electrónico no puede estar vacío.' });
    } else if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(email)) {
      errors.push({ field: 'email', message: 'El correo electrónico no es válido.' });
    }

    if (!password || !password.trim()) {
      errors.push({ field: 'password', message: 'La contraseña no puede estar vacía.' });
    }

    return errors;
};

export const validateFaq = (title: string, description: string): ValidationError[] => {
    const errors: ValidationError[] = [];

    if (!title || !title.trim()) {
        errors.push({ field: "title", message: "El título no puede estar vacío." });
    }

    if (!description || !description.trim()) {
        errors.push({ field: "description", message: "La respuesta no puede estar vacía." });
    }

    return errors;
};

export const validateCategory = (name: string): ValidationError[] => {
    const errors: ValidationError[] = [];

    if (!name || !name.trim()) {
        errors.push({ field: "name", message: "El nombre no puede estar vacío." });
    }

    return errors;
};

export const validatePackage = (title: string, price: string, packageTypeId: number, description: string): ValidationError[] => {
    const errors: ValidationError[] = [];

    if (!title || !title.trim()) {
        errors.push({ field: "title", message: "El titulo no puede estar vacío." });
    }

    if (!packageTypeId) {
        errors.push({ field: "categorie", message: "La categoria no puede estar vacía." });
    }

    if (!price) {
        errors.push({ field: "price", message: "El precio no puede estar vacío." });
    }

    if (!description || !description.trim()) {
        errors.push({ field: "description", message: "La descripción no puede estar vacía." });
    }

    return errors;
};

export const validateClasses = (title: string, description: string): ValidationError[] => {
    const errors: ValidationError[] = [];

    if (!title || !title.trim()) {
        errors.push({ field: "title", message: "El titulo no puede estar vacío." });
    }

    if (!description || !description.trim()) {
        errors.push({ field: "description", message: "La descripción no puede estar vacía." });
    }

    return errors;
};

export const validateCoupon = (name: string, code: string, dateExpired: string): ValidationError[] => {
    const errors: ValidationError[] = [];

    if (!name || !(typeof name === 'string' && name.trim())) {
        errors.push({ field: "name", message: "El nombre no puede estar vacío." });
    }
    console.debug(code, 'codecodecode')
    if (!code || !(typeof code === 'string' && code.trim())) {
        errors.push({ field: "code", message: "El código no puede estar vacío." });
    }

    if (!dateExpired || !(dateExpired instanceof Date) || isNaN(dateExpired.getTime())) {
        errors.push({ field: "dateExpired", message: "La fecha de expiración no es válida." });
    }

    return errors;
};

export const validateSchedule = (schedule: { id: number; title: string; date: string; hourEnd: string; hourStart: string; trainerId: number; quantity: string; classId: string; },
    currentScheduleDate?: string
    ): ValidationError[] => {
    const errors: ValidationError[] = [];

    if (!schedule.title || !(typeof schedule.title === 'string' && schedule.title.trim())) {
        errors.push({ field: "title", message: "El título no puede estar vacío." });
    }

    if (!schedule.date) {
        errors.push({ field: "date", message: "La fecha no puede estar vacía." });
    } else if (!moment(schedule.date, 'YYYY-MM-DD', true).isValid()) {
        errors.push({ field: "date", message: "La fecha debe tener un formato válido (YYYY-MM-DD)." });
    } else if (moment(schedule.date).isBefore(moment(), 'day')) {
        errors.push({ field: "date", message: "La fecha no puede ser anterior al día actual." });
    } else if (currentScheduleDate && moment(schedule.date).isBefore(moment(currentScheduleDate), 'day')) {
        errors.push({ field: "date", message: "La nueva fecha no puede ser anterior a la fecha ya existente." });
    }

    if (!schedule.hourStart) {
        errors.push({ field: "hourStart", message: "La hora de inicio no puede estar vacía." });
    } else if (!moment(schedule.hourStart, 'HH:mm', true).isValid()) {
        errors.push({ field: "hourStart", message: "La hora de inicio debe tener un formato válido (HH:mm)." });
    }

    if (!schedule.hourEnd) {
        errors.push({ field: "hourEnd", message: "La hora de fin no puede estar vacía." });
    } else if (!moment(schedule.hourEnd, 'HH:mm', true).isValid()) {
        errors.push({ field: "hourEnd", message: "La hora de fin debe tener un formato válido (HH:mm)." });
    } else if (moment(schedule.hourStart, 'HH:mm').isSameOrAfter(moment(schedule.hourEnd, 'HH:mm'))) {
        errors.push({ field: "hourEnd", message: "La hora de fin debe ser posterior a la hora de inicio." });
    }

    if (!schedule.quantity || isNaN(Number(schedule.quantity)) || Number(schedule.quantity) <= 0) {
        errors.push({ field: "quantity", message: "La cantidad debe ser un número válido y mayor que cero." });
    }

    if (!schedule.classId) {
        errors.push({ field: "classId", message: "La clase no puede estar vacía." });
    }

    return errors;
};

export const validateUser = (user: Users, validatePassword = true): ValidationError[] => {
    const errors: ValidationError[] = [];
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phoneRegex = /^(\+?\d{1,3}[-\s]?)?(\(?\d{1,4}\)?[-\s]?)?(\d{4}[-\s]?\d{4})$/;

    if (!user.name) {
        errors.push({ field: "name", message: "El nombre no puede estar vacío." });
    }

    if (!user.lastname) {
        errors.push({ field: "lastname", message: "El apellido no puede estar vacío." });
    }

    if (!user.email) {
        errors.push({ field: "email", message: "El email no puede estar vacío." });
    } else if (!emailRegex.test(user.email)) {
        errors.push({ field: "email", message: "El email debe tener un formato válido." });
    }

    if (validatePassword && !user.password) {
        errors.push({ field: "password", message: "La contraseña no puede estar vacía." });
    }

    if (!user.profile) {
        errors.push({ field: "profile", message: "El perfil no puede estar vacío." });
    }

    if (!user.phone) {
        errors.push({ field: "phone", message: "El teléfono no puede estar vacío." });
    } else if (!phoneRegex.test(user.phone)) {
        errors.push({ field: "phone", message: "Ingrese un formato correcto." });
    }

    if (user.active === undefined || user.active === null) {
        errors.push({ field: "active", message: "El estado activo no puede estar vacío." });
    }

    if (!user.dni) {
        errors.push({ field: "dni", message: "El DNI no puede estar vacío." });
    }

    if (!user.birthdate) {
        errors.push({ field: "birthdate", message: "La fecha de nacimiento no puede estar vacía." });
    } else {
        const today = new Date();
        const birthDate = new Date(user.birthdate);

        const age = today.getFullYear() - birthDate.getFullYear();
        const month = today.getMonth() - birthDate.getMonth();
        if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        if (age < 10) {
            errors.push({ field: "birthdate", message: "Ingresa una fecha de nacimiento válida." });
        }
    }

    return errors;
};