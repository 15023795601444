import React, { useState,useEffect } from 'react'
import * as Icon from 'react-feather';
import { apiRepository, Faq } from '../../../../api/apiRepository';
import { validateFaq, ValidationError } from '../../../../core/form/validationForm';


export interface UpdateFaqModalProps {
    selectedFaq: { id: number; title: string; description: string; status: boolean };
    onUpdateSuccess?: () => void;
}

const FaqModal: React.FC<UpdateFaqModalProps> = ({ selectedFaq, onUpdateSuccess }) => {

    const [id, setId] = useState<number>(selectedFaq ? selectedFaq.id : -1);
    const [title, setTitle] = useState<string>(selectedFaq ? selectedFaq.title : '');
    const [description, setDescription] = useState<string>(selectedFaq ? selectedFaq.description : '');
    const [status, setStatus] = useState<boolean>(selectedFaq ? selectedFaq.status : true);
    const [errors, setErrors] = useState<ValidationError[]>([]);
    const [isValidated, setIsValidated] = useState(false);


    useEffect(() => {
        if (selectedFaq) {
            setId(selectedFaq.id);
            setTitle(selectedFaq.title);
            setDescription(selectedFaq.description);
            setStatus(selectedFaq.status);
        }
    }, [selectedFaq]);

    useEffect(() => {
        const validationErrors = validateFaq(title, description);
        if (validationErrors.length === 0) {
            setIsValidated(true);
        }
    }, [title, description]);

    const resetFields = () => {
        setId(-1);
        setTitle('');
        setDescription('');
        setStatus(true);
        setErrors([]);
        setIsValidated(false);
    };

    useEffect(() => {

        const addModalElement = document.getElementById('add-faq');
        const editModalElement = document.getElementById('edit-faq');

        addModalElement?.addEventListener('hide.bs.modal', resetFields);
        editModalElement?.addEventListener('hide.bs.modal', resetFields);

        return () => {
            addModalElement?.removeEventListener('hide.bs.modal', resetFields);
            editModalElement?.removeEventListener('hide.bs.modal', resetFields);
        };
    }, []);


    const handleConfirmCreate = async (event: React.FormEvent) => {
        event.preventDefault()

        const validationErrors = validateFaq(title, description);
        if (validationErrors.length > 0) {
            setErrors(validationErrors);
            return;
        } else {
            setErrors([]);
        }

        const newFaq: Faq = { id: id, title, description, status };

        try {
            if (id == -1) {
                await apiRepository.createFaq(newFaq);
            } else {
                await apiRepository.updateFaq(id, newFaq);
            }
            console.log('FAQ modificado!');
            if (onUpdateSuccess) {
                onUpdateSuccess();
            }
        } catch (error) {
            console.error("Error al crear FAQ:", error);
        }
    };

    return (
        <>
            {/* Add FAQ */}
            <div className="modal fade" id="add-faq">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Agregar Pregunta</h5>
                            <button
                                type="button"
                                className="btn-close close-modal"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <Icon.X className="react-feather-custom " />
                            </button>
                        </div>
                        <div className="modal-body pt-0">
                            <form onSubmit={handleConfirmCreate}>
                                <div className="mb-3">
                                    <label className="form-label">Titulo</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        />
                                        {errors.find(error => error.field === 'title') && (
                                            <small className="text-danger">
                                                {errors.find(error => error.field === 'title')?.message}
                                            </small>
                                        )}
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Respuesta</label>
                                    <textarea
                                    className="form-control"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    />
                                    {errors.find(error => error.field === 'description') && (
                                        <small className="text-danger">
                                            {errors.find(error => error.field === 'description')?.message}
                                        </small>
                                    )}
                                </div>
                                <div className="text-end">
                                    <button
                                        type="button"
                                        className="btn btn-secondary me-2"
                                        data-bs-dismiss="modal"
                                    >
                                        Cancelar
                                    </button>
                                    <button
                                    type="submit"
                                    className="btn btn-primary"
                                    data-bs-dismiss={isValidated ? "modal" : undefined}
                                    >
                                        Agregar
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Add FAQ */}
            {/* Edit FAQ */}
            <div className="modal fade" id="edit-faq">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Edit FAQ</h5>
                            <button
                                type="button"
                                className="btn-close close-modal"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <Icon.X className="react-feather-custom" />
                            </button>
                        </div>
                        <div className="modal-body pt-0">
                            <form  onSubmit={handleConfirmCreate}>
                                <div className="mb-3">
                                    <label className="form-label">Title</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                    />
                                    {errors.find(error => error.field === 'title') && (
                                        <small className="text-danger">
                                            {errors.find(error => error.field === 'title')?.message}
                                        </small>
                                    )}
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Answer</label>
                                    <textarea
                                        className="form-control"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                    {errors.find(error => error.field === 'description') && (
                                        <small className="text-danger">
                                            {errors.find(error => error.field === 'description')?.message}
                                        </small>
                                    )}
                                </div>
                                <div className="form-groupheads d-flex d-flex justify-content-between mb-4">
                                    <h2>Status</h2>
                                    <div className="active-switch">
                                        <label className="switch">
                                            <input type="checkbox"
                                            checked={status}
                                            onChange={(e) => setStatus(e.target.checked)}
                                        />
                                        <span className="sliders round" />
                                        </label>
                                    </div>
                                </div>
                                <div className="text-end">
                                    <button
                                        type="button"
                                        className="btn btn-secondary me-2"
                                        data-bs-dismiss="modal"
                                    >
                                        Cancelar
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        data-bs-dismiss="modal"
                                    >
                                        Guardar cambios
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Edit FAQ */}
        </>
    )
}

export default FaqModal
