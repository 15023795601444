import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { all_routes } from '../../../../core/data/routes/all_routes';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import BreadCrumb from '../../common/breadcrumb/breadCrumb';
import CustomerSideBar from '../common/sidebar';
import CountUp from 'react-countup';
import DashWidget from '../../common/dashWidget/dashWidget';
import { apiRepository } from '../../../../api/apiRepository';
import {
  InputField,
  CalendarField,
  TimePickerField,
  DropdownField,
} from '../../../admin/common/input/inputField';
import { validateUser, ValidationError } from '../../../../core/form/validationForm';
import { handleChange, formatTo12Hour, formatDateToDayMonth } from '../../../../core/utils/utils';

const CustomerDashboard = () => {
  const routes = all_routes;
  const [totalRecords, setTotalRecords] = useState(0);
  const [classesToday, setClassesToday] = useState(0);
  const [nextClass, setNextClass] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    lastname: '',
    dni: '',
    phone: '',
    email: '',
    birthdate: null,
  });
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState<string>('');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    handleChange(e, setFormData);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      const userID = localStorage.getItem('userId');
      try {
        const response = await apiRepository.getIdUsers(userID);
        const userData = response.data.data;
        setFormData({
          name: userData.name || '',
          lastname: userData.lastname || '',
          dni: userData.dni || '',
          phone: userData.phone || '',
          email: userData.email || '',
          password: userData.password || '',
          profile: userData.profile || '',
          active: userData.active || '',
          birthdate: new Date(userData.birthdate) || null,
        });
      } catch (error: any) {
        console.error('Error al cargar los datos del usuario:', error);
        const errorMessage = error.response?.data?.message || 'Error al procesar la solicitud.';
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const updatePerfil = async (e: React.FormEvent) => {
    e.preventDefault();
    const userID = localStorage.getItem('userId')
    const validationErrors = validateUser(formData, formData.password);

    if (formData.password !== newPassword) {
      validationErrors.push({ field: "newPassword", message: "Las contraseñas deben ser iguales" });
    }

    if (validationErrors.length > 0) {
      setErrors(validationErrors);
      return;
    }

    setLoading(true);
    try {
      await apiRepository.updateUser(userID, formData);
      setErrors([]);
      validationErrors.push({ field: "success", message: '¡La actualización de datos fue exitosa!'});
      setErrors(validationErrors);

    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.message) {
        const cleanedMessage = error.response.data.message.replace(/^Bad request: /, '');
        validationErrors.push({ field: "server", message: cleanedMessage});
      } else {
        validationErrors.push({ field: "server", message: 'Ocurrió un error al intentar iniciar sesión. Inténtalo de nuevo.'});
      }
      setErrors(validationErrors);

    } finally {
      setLoading(false);
    }
  };

  const fetchSchedules = async () => {
    setLoading(true);
    try {
      const response = await apiRepository.getSchedules();
      const schedules = response.data.data;

      setTotalRecords(schedules.length);

      const today = new Date().toISOString().split('T')[0];
      const todayClasses = schedules.filter((cls) => cls.date === today);
      setClassesToday(todayClasses.length);

      const futureClasses = schedules
        .filter((cls) => new Date(cls.date + 'T' + cls.hourStart) > new Date())
        .sort((a, b) =>
          new Date(a.date + 'T' + a.hourStart) - new Date(b.date + 'T' + b.hourStart)
        );

      if (futureClasses.length > 0) {
        const next = futureClasses[0];
        setNextClass({
          ...next,
          dateFormatted: formatDateToDayMonth(next.date),
          hourStartFormatted: formatTo12Hour(next.hourStart),
          hourEndFormatted: formatTo12Hour(next.hourEnd),
        });
      } else {
        setNextClass(null);
      }
    } catch (error) {
      console.error('Error al obtener los datos de usuarios:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSchedules();
  }, []);

  const widgets = [
    {
      iconClass: 'ti ti-chart-line',
      bgClass: 'bg-primary-transparent',
      title: 'Clases Totales',
      value: totalRecords,
      duration: 1,
    },
    {
      iconClass: 'ti ti-calendar',
      bgClass: 'bg-secondary-transparent',
      title: 'Clases de hoy',
      value: classesToday
        ? `${classesToday.date} ${classesToday.hourStart}`
        : 'Sin clases hoy',
      duration: 2,
    },
    {
      iconClass: 'ti ti-alarm',
      bgClass: 'bg-success-transparent',
      title: 'Siguiente Clase',
      value: nextClass
        ? nextClass.dateFormatted
        : 'Sin clases próximas',
      duration: 2,
      hourStart: nextClass ? nextClass.hourStartFormatted : undefined,
      hourEnd: nextClass ? nextClass.hourEndFormatted : undefined,
    },
  ];

  return (
    <>
      <BreadCrumb title="Dashboard" item1="Perfil" item2="Dashboard" />
      <>
        {/* Page Wrapper */}
        <div className="page-wrapper">
          <div className="content">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-3 col-lg-4 ">
                  <CustomerSideBar />
                </div>
                <div className="col-xl-9 col-lg-8">
                  <h4 className="mb-3">Dashboard</h4>
                  <div className="row mb-2">
                      {widgets.map((widget, index) => (
                        <DashWidget key={index} {...widget} />
                      ))}
                    </div>
                    <div className="row">
                    <h4 className="mb-3">Información</h4>
                    <form onSubmit={updatePerfil}>
                      <div className="row mb-4">
                        <div className="col-md-6">
                          <InputField
                            label="Nombre"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            error={errors.find((err) => err.field === 'name')?.message}
                          />
                        </div>
                        <div className="col-md-6">
                          <InputField
                            label="Apellido"
                            name="lastname"
                            value={formData.lastname}
                            onChange={handleInputChange}
                            error={errors.find((err) => err.field === 'lastname')?.message}
                          />
                        </div>
                        <div className="col-md-6">
                          <InputField
                            label="DNI"
                            name="dni"
                            value={formData.dni}
                            onChange={handleInputChange}
                            error={errors.find((err) => err.field === 'dni')?.message}
                          />
                        </div>
                        <div className="col-md-6">
                          <InputField
                            label="Telefono"
                            name="phone"
                            value={formData.phone}
                            onChange={handleInputChange}
                            error={errors.find((err) => err.field === 'phone')?.message}
                          />
                        </div>
                        <div className="col-md-6">
                          <InputField
                            label="Correo"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            error={errors.find((err) => err.field === 'email')?.message}
                          />
                        </div>
                        <div className="col-md-6">
                          <CalendarField
                            label="Fecha de nacimiento"
                            name="birthdate"
                            value={formData.birthdate}
                            onChange={(e) => setFormData({ ...formData, birthdate: e.value })}
                            error={errors.find((err) => err.field === 'birthdate')?.message}
                          />
                        </div>
                        <div className="col-md-6">
                          <InputField
                            type='password'
                            label="Nueva Contraseña"
                            name='password'
                            value={formData.password}
                            onChange={handleInputChange}
                            error={errors.find((err) => err.field === 'password')?.message}
                          />
                        </div>
                        <div className="col-md-6">
                          <InputField
                              type='password'
                              label="Confirmar Contraseña"
                              value={newPassword}
                              onChange={(e) => setNewPassword(e.target.value)}
                              error={errors.find((err) => err.field === 'newPassword')?.message}
                          />
                        </div>
                        {errors.find(error => error.field === 'server') && (
                          <div className="alert alert-danger" role="alert">
                            {errors.find(error => error.field === 'server')?.message}
                          </div>
                        )}
                        {errors.find(error => error.field === 'success') && (
                          <div className="alert alert-success" role="alert">
                            {errors.find(error => error.field === 'success')?.message}
                          </div>
                        )}
                      </div>
                      <div className="d-flex justify-content-end align-items-center">
                        <Link to="#" className="btn btn-light me-2">
                          Cancelar
                        </Link>
                        <button type="submit" className="btn btn-dark" disabled={loading}>
                          {loading ? 'Guardando...' : 'Guardar Cambios'}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Wrapper */}
      </>
    </>
  );
};

export default CustomerDashboard;
