import { DataTable } from 'primereact/datatable';
import React, { useState, useEffect } from 'react';
import { Column } from 'primereact/column';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import ClassesModal from '../common/modals/classes-modal';
import DeleteClassesModal from '../common/modals/delete-classes-modal';
import { apiRepository, Package } from '../../../api/apiRepository';

const Classes = () => {
    const [data, setData] = useState<Package[]>([]);
    const [selectedClassesId, setSelectedPackageId] = useState<number>(-1);
    const [selectedClasses, setSelectedPackage] = useState<string | null>(null);

    const fetchPackages = async () => {
        try {
            const response = await apiRepository.getClasses();
            setData(response.data.data);
        } catch (error) {
            console.error("Error al obtener los datos de paquetes:", error);
        }
    };

    useEffect(() => {
        fetchPackages();
    }, []);

    const handleUpdateSuccess = () => {
        fetchPackages();
    };

    const actionButton = (rowData: any) => {
        const { id, title, description, status, image } = rowData;

        const handleEditClick = () => {
            setSelectedPackage({ id, title,  description, status, image });
        };

        const handleDeleteClick = () => {
            setSelectedPackageId(id);
        };

        return (
            <div className="table-actions d-flex">
                <Link
                    className="delete-table me-2"
                    onClick={handleEditClick}
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#edit-classes"
                >
                    <Icon.Edit className="react-feather-custom" />
                </Link>
                <Link
                    className="delete-table"
                    onClick={handleDeleteClick}
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#delete-classes"
                >
                    <Icon.Trash2 className="react-feather-custom" />
                </Link>
            </div>
        );
    };

    return (
        <>
            <div className="page-wrapper page-settings">
                <div className="content">
                    <div className="content-page-header content-page-headersplit">
                        <h5>Clases</h5>
                        <div className="list-btn">
                            <ul>
                                <li>
                                    <button
                                        className="btn btn-primary"
                                        type="button"
                                        data-bs-toggle="modal"
                                        data-bs-target="#add-classes"
                                    >
                                        <i className="fa fa-plus me-2" />
                                        Agregar Paquete
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="table-resposnive">
                                <DataTable
                                    paginatorTemplate="RowsPerPageDropdown CurrentPageReport PrevPageLink PageLinks NextPageLink"
                                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                                    value={data}
                                    showGridlines
                                    tableStyle={{ minWidth: '50rem' }}
                                >
                                    <Column sortable field="title" header="Titulo" />
                                    <Column sortable field="description" header="Descripción" />
                                    <Column header="Acción" body={actionButton} />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ClassesModal selectedClasses={selectedClasses} onUpdateSuccess={handleUpdateSuccess}/>
            <DeleteClassesModal selectedClassesId={selectedClassesId} onDeleteSuccess={handleUpdateSuccess}/>
        </>
    );
};

export default Classes;
