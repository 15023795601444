import { api } from "../config/apiConfig";
import { AxiosResponse } from "axios";

const endpoints = {
  faqs: "faqs",
  coupons: "coupons",
  pages: "pages",
  packageTypes: "package-types",
  package: "packages",
  classes: "classes",
  users: "users",
  banners: "banners",
  auth: "auth/login",
  schedules: "schedules",
};

export interface Faq {
  id: number;
  title: string;
  description: string;
  status: boolean;
}

export interface Coupon {
  id: number;
  name: string;
  code: string;
  dateExpired: string;
}

export interface Pages {
  id: number;
  description: string;
}

export interface packageTypes {
  id: number;
  name: string;
  status: boolean;
}

export interface Package {
  id: number;
  packageTypeId: number;
  title: string;
  description: string;
  price: string;
  status: boolean;
}

export interface Classes {
  title: string;
  description: string;
  path: string;
  file: File | null;
  status: boolean;
}

export interface Users {
  name: string;
  lastname: string;
  email: string;
  password: string;
  profile: string;
  phone: string;
  birthdate: string;
  dni: string;
  active: boolean;
}

export interface Banners {
  title: string;
  subtitle: string;
  path: string;
  image: string;
  status: boolean;
  position: number;
}

export interface Auth {
  email: string;
  password: string;
}

export interface Schedules {
  date: string;
  houtStart: string;
  hourEnd: string;
  title: string;
  trainerId: string;
  quantity: string;
  classId: string;
}

export const apiRepository = {

  getFaqs: (): Promise<AxiosResponse<Faq[]>> => api.get(endpoints.faqs),
  createFaq: (data: Faq): Promise<AxiosResponse<Faq>> => api.post(endpoints.faqs, data),
  updateFaq: (id: number, data: Faq): Promise<AxiosResponse<Faq>> => api.put(`${endpoints.faqs}/${id}`, data),
  deleteFaq: (id: number): Promise<AxiosResponse<void>> => api.delete(`${endpoints.faqs}/${id}`),

  getCoupons: (): Promise<AxiosResponse<Coupon[]>> => api.get(endpoints.coupons),
  createCoupon: (data: Coupon): Promise<AxiosResponse<Coupon>> => api.post(endpoints.coupons, data),
  updateCoupon: (id: number, data: Coupon): Promise<AxiosResponse<Coupon>> => api.put(`${endpoints.coupons}/${id}`, data),
  deleteCoupon: (id: number): Promise<AxiosResponse<void>> => api.delete(`${endpoints.coupons}/${id}`),

  getPage: (id: number): Promise<AxiosResponse<Pages>> => api.get(`${endpoints.pages}/${id}`),
  updatePage: (id: number, data: Pages): Promise<AxiosResponse<Pages>> => api.put(`${endpoints.pages}/${id}`, data),

  getPackageTypes: (): Promise<AxiosResponse<packageTypes[]>> => api.get(endpoints.packageTypes),
  createPackageTypes: (data: packageTypes): Promise<AxiosResponse<packageTypes>> => api.post(`${endpoints.packageTypes}`, data),
  updatePackageTypes: (id: number, data: packageTypes): Promise<AxiosResponse<packageTypes>> => api.put(`${endpoints.packageTypes}/${id}`, data),
  deletePackageTypes: (id: number): Promise<AxiosResponse<void>> => api.delete(`${endpoints.packageTypes}/${id}`),

  getPackage: (): Promise<AxiosResponse<Package[]>> => api.get(endpoints.package),
  createPackage: (data: Package): Promise<AxiosResponse<Package>> => api.post(`${endpoints.package}`, data),
  updatePackage: (id: number, data: Package): Promise<AxiosResponse<Package>> => api.put(`${endpoints.package}/${id}`, data),
  deletePackage: (id: number): Promise<AxiosResponse<void>> => api.delete(`${endpoints.package}/${id}`),

  getClasses: (): Promise<AxiosResponse<Classes[]>> => api.get(endpoints.classes),
  createClasses: (data: FormData): Promise<AxiosResponse<Classes>> => api.post(`${endpoints.classes}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }),
  updateClasses: (id: number, data: FormData): Promise<AxiosResponse<Classes>> => api.put(`${endpoints.classes}/${id}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }),
  deleteClasses: (id: number): Promise<AxiosResponse<void>> => api.delete(`${endpoints.classes}/${id}`),

  getUsers: (): Promise<AxiosResponse<Users[]>> => api.get(endpoints.users),
  getIdUsers: (id: number): Promise<AxiosResponse<Users[]>> => api.get(`${endpoints.users}/${id}`),
  createUser: (data: Users): Promise<AxiosResponse<Users>> => api.post(`${endpoints.users}`, data),
  updateUser: (id: number, data: Users): Promise<AxiosResponse<Users>> => api.put(`${endpoints.users}/${id}`, data),
  deleteUser: (id: number): Promise<AxiosResponse<void>> => api.delete(`${endpoints.users}/${id}`),

  getBanners: (): Promise<AxiosResponse<Banners[]>> => api.get(endpoints.banners),
  createBanner: (data: Banners): Promise<AxiosResponse<Banners>> => api.post(`${endpoints.banners}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }),
  updateBanner: (id: number, data: Banners): Promise<AxiosResponse<Banners>> => api.put(`${endpoints.banners}/${id}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }),
  deleteBanner: (id: number): Promise<AxiosResponse<void>> => api.delete(`${endpoints.banners}/${id}`),

  authUser: (data: Auth): Promise<AxiosResponse<Auth>> => api.post(`${endpoints.auth}`, data),

  getSchedules: (): Promise<AxiosResponse<Schedules[]>> => api.get(endpoints.schedules),
  getIdSchedules: (id: number): Promise<AxiosResponse<Schedules[]>> => api.get(`${endpoints.schedules}/${id}`),
  createSchedule: (data: Schedules): Promise<AxiosResponse<Schedules>> => api.post(`${endpoints.schedules}`, data),
  updateSchedule: (id: number, data: Schedules): Promise<AxiosResponse<Schedules>> => api.put(`${endpoints.schedules}/${id}`, data),
  deleteSchedule: (id: number): Promise<AxiosResponse<void>> => api.delete(`${endpoints.schedules}/${id}`),

};