import React, { useState, useEffect } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import UsersModal from '../common/modals/users-modal';
import DeleteUserModal from '../common/modals/delete-user-modal';
import { apiRepository, Users } from '../../../api/apiRepository';

const UsersManage = () => {
  const [data, setData] = useState<Users[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<number>(-1);
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(10);

  const fetchUsers = async (pageNumber = 0, pageSize = 10) => {
    setLoading(true);
    try {
      const response = await apiRepository.getUsers({
        page: pageNumber,
        size: pageSize,
      });
      setData(response.data.data.content);
      setTotalRecords(response.data.data.totalElements);
    } catch (error) {
      console.error('Error al obtener los datos de usuarios:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateSuccess = () => {
    fetchUsers(page, rows);
  };

  useEffect(() => {
    fetchUsers(page, rows);
  }, [page, rows]);

  const onPageChange = (e) => {
    setPage(e.page);
    setRows(e.rows);
    fetchUsers(e.page, e.rows);
  };

  const actionButton = (rowData: any) => {
    const { id, name, lastname, email, password, profile, active, phone, dni, birthdate } = rowData;

    const handleEditClick = () => {
      setSelectedUser({id, name, lastname, email, password, profile, active, phone, dni, birthdate });
    };

    const handleDeleteClick = () => {
      setSelectedUserId(id);
    };

    return (
      <div className="table-actions d-flex">
        <Link
          className="delete-table me-2"
          to="#"
          onClick={handleEditClick}
          data-bs-toggle="modal"
          data-bs-target="#edit-user"
        >
          <Icon.Edit className="react-feather-custom" />
        </Link>
        <Link
          className="delete-table"
          to="#"
          onClick={handleDeleteClick}
          data-bs-toggle="modal"
          data-bs-target="#delete-user"
        >
          <Icon.Trash2 className="react-feather-custom" />
        </Link>
      </div>
    );
  };

  return (
    <>
      <div className="page-wrapper page-settings">
        <div className="content">
          <div className="content-page-header content-page-headersplit">
            <h5>Usuarios</h5>
            <div className="list-btn">
              <ul>
                <li>
                  <button className="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#add-user">
                    <i className="fa fa-plus me-2" />
                    Agregar Usuario
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="table-responsive table-div">
                <table className="table datatable">
                  <DataTable
                    value={data}
                    paginator
                    rows={rows}
                    totalRecords={totalRecords} // Total de elementos
                    first={page * rows} // Página actual
                    onPage={onPageChange} // Manejador de cambios en la paginación
                    loading={loading}
                    showGridlines
                    tableStyle={{ minWidth: '50rem' }}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                  >
                    <Column sortable field="id" header="#"></Column>
                    <Column sortable filter field="name" header="Nombre Completo"></Column>
                    <Column sortable filter field="lastname" header="Apellido"></Column>
                    <Column field="phone" header="Telefono" filter></Column>
                    <Column sortable field="profile" header="Rol" filter filterField="profile"></Column>
                    <Column field="dni" header="DNI" filter filterField="dni"></Column>
                    <Column field="email" header="Correo" filter filterField="email"></Column>
                    <Column field="birthdate" header="Nacimiento"></Column>
                    <Column
                      sortable
                      field="active"
                      header="Status"
                      body={(rowData) => (
                        <span className={rowData.active ? 'badge-active' : 'badge-inactive'}>
                          {rowData.active ? 'Active' : 'Inactive'}
                        </span>
                      )}
                    ></Column>
                    <Column body={actionButton}></Column>
                  </DataTable>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UsersModal selectedUser={selectedUser} onUpdateSuccess={handleUpdateSuccess} />
      <DeleteUserModal selectedUserId={selectedUserId} onDeleteSuccess={handleUpdateSuccess} />
    </>
  );
};

export default UsersManage;