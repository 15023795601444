import React from 'react';
import { apiRepository } from '../../../../api/apiRepository';
import * as Icon from 'react-feather';

export interface DeleteCouponsModalProps {
    selectedPackageId: number;
    onDeleteSuccess?: () => void;
}

const DeleteCouponsModal: React.FC<DeleteCouponsModalProps> = ({ selectedPackageId, onDeleteSuccess }) => {


    const handleConfirmDelete = async () => {
        if (selectedPackageId === -1) return;

        try {
            console.log("Intentando eliminar cupón con ID:", selectedPackageId);
            await apiRepository.deletePackage(selectedPackageId);
            console.log("Cupón eliminado con éxito");

            if (onDeleteSuccess) {
                onDeleteSuccess();
            }
        } catch (error) {
            console.error("Error al eliminar el cupón:", error);
        }
    };

    return (
        <div>
            <div className="modal fade" id="delete-package">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body pt-0">
                            <div className="text-center">
                                <Icon.Trash2 size={45} color='#f05050' className="text-danger fs-1" />
                                <div className="mt-4">
                                    <h4>¿Eliminar Cupón?</h4>
                                    <p className="text-muted mb-0">¿Estás seguro de que quieres eliminar este cupón?</p>
                                </div>
                            </div>
                            <div className="d-flex gap-2 justify-content-center mt-4">
                                <button
                                    type="button"
                                    className="btn w-sm btn-secondary"
                                    data-bs-dismiss="modal"
                                >
                                    Cancelar
                                </button>
                                <button
                                    type="button"
                                    className="btn w-sm btn-danger"
                                    data-bs-dismiss="modal"
                                    onClick={handleConfirmDelete}
                                >
                                    Sí, Borrar Cupón
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DeleteCouponsModal;