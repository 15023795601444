import { DataTable } from 'primereact/datatable'
import React, { useState, useEffect } from 'react'
import { Column } from 'primereact/column';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import CatogriesModal from '../common/modals/catogries-modal';
import DeleteCategoriesModal from '../common/modals/delete-categories-modal';
import { apiRepository, packageTypes } from '../../../api/apiRepository';

const CategoriesList = () => {
    const [selectedCategoryId, setSelectedCategoryId] = useState<number>(-1);
    const [selectedCategory, setSelectedCategory] = useState<string |  null>(null);
    const [data, setData] = useState<packageTypes[]>([]);

    const fetchCategories = async () => {
        try {
          const response = await apiRepository.getPackageTypes();
          setData(response.data.data);
        } catch (error) {
          console.error("Error al obtener los datos de FAQs:", error);
        }
      };

    const formatDate = (dateString:any) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('es-PE', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    const handleUpdateSuccess = () => {
        fetchCategories();
      };

    const actionButton = (rowData:any) => {
        const { id, name, status } = rowData;

        const handleDataClick = () => {
            setSelectedCategory({id, name, status});
            setSelectedCategoryId(id);
          };
        return (
            <div className="table-actions d-flex">
                <Link
                    className="delete-table me-2"
                    onClick={handleDataClick}
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#edit-category"
                >
                    <Icon.Edit className='react-feather-custom' />
                </Link>
                <Link
                    className="delete-table border-none"
                    onClick={handleDataClick}
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#delete-category"
                >
                    <Icon.Trash2 className='react-feather-custom' />
                </Link>
            </div>
        )
    }

    return (
        <>
            <div className="page-wrapper page-settings">
                <div className="content">
                    <div className="content-page-header content-page-headersplit mb-0">
                        <h5>Categories</h5>
                        <div className="list-btn">
                            <ul>
                                <li>
                                    <button
                                        className="btn btn-primary"
                                        type="button"
                                        data-bs-toggle="modal"
                                        data-bs-target="#add-category"
                                    >
                                        <i className="fa fa-plus me-2" />
                                        Add Category
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 ">
                            <div className="table-resposnive table-div">
                                <DataTable
                                    value={data}
                                    showGridlines
                                    tableStyle={{ minWidth: '50rem' }}
                                >
                                    <Column sortable field="id" header="ID" ></Column>
                                    <Column sortable field="name" header="Categories" ></Column>
                                    <Column sortable field="createdAt" header="Date" body={(rowData) => formatDate(rowData.createdAt)}></Column>
                                    <Column
                                        sortable
                                        field="Action"
                                        header="Action"
                                        body={actionButton}
                                    ></Column>
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CatogriesModal selectedCategory={selectedCategory} onUpdateSuccess={handleUpdateSuccess}/>
            <DeleteCategoriesModal selectedCategoryId={selectedCategoryId} onDeleteSuccess={handleUpdateSuccess}/>
        </>
    )
}

export default CategoriesList
