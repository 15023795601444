import React, { useState, useEffect, useCallback  } from 'react'
import * as Icon from 'react-feather';
import { validateSchedule, ValidationError } from '../../../../core/form/validationForm';
import { apiRepository } from '../../../../api/apiRepository';
import { InputField, CalendarField, TimePickerField, DropdownField } from '../../../admin/common/input/inputField';

export interface UpdateScheduleModalProps {
    selectedSchedule?: { id: number; title: string; date: string; hourEnd: string; hourStart: string; trainerId: number; quantity: string; classId: string; };
    onUpdateSuccess?: () => void;
}

const SchedulesModal: React.FC<UpdateScheduleModalProps> = ({ selectedSchedule, onUpdateSuccess } ) => {
    const [formData, setFormData] = useState({
        id: selectedSchedule?.id || -1,
        title: selectedSchedule?.title || '',
        date: selectedSchedule ? new Date(selectedSchedule.date + "T00:00:00") : null,
        hourStart: selectedSchedule?.hourStart ||  '',
        hourEnd: selectedSchedule?.hourEnd ||  '',
        trainerId: Number(localStorage.getItem('userId')),
        quantity: selectedSchedule?.quantity || '',
        classId: selectedSchedule?.classId || '',
    });
    const [errors, setErrors] = useState<ValidationError[]>([]);
    const [currentDate, setCurrentDate] = useState<Date | null>(selectedSchedule ? new Date(selectedSchedule.date + "T00:00:00" ) : null);
    const [isValidated, setIsValidated] = useState(false);
    const [classes, setClasses] = useState<{ label: string, value: string }[]>([]);
    const [serverError, setServerError] = useState<string | null>(null);

    const loadClasses = useCallback(async () => {
        try {
            const classData = await apiRepository.getClasses();
            setClasses(classData.data.data.map((classItem: { id: string, title: string }) => ({
                label: classItem.title,
                value: classItem.id,
            })));
        } catch (error) {
            console.error('Error al obtener las clases:', error);
        }
    }, []);

    useEffect(() => {
        loadClasses();
    }, [loadClasses]);

    useEffect(() => {
        const validationErrors = validateSchedule(formData, selectedSchedule?.date);
        console.debug(validationErrors, 'validationErrors');
        setIsValidated(validationErrors.length === 0);
    }, [formData, selectedSchedule]);

    useEffect(() => {
        if (selectedSchedule) {
            setFormData({
                id: selectedSchedule.id,
                title: selectedSchedule.title,
                date: new Date(`${selectedSchedule.date}T00:00:00`),
                hourStart: selectedSchedule.hourStart,
                hourEnd: selectedSchedule.hourEnd,
                trainerId: Number(localStorage.getItem('userId')),
                quantity: selectedSchedule.quantity,
                classId: selectedSchedule.classId,
            });
        }
    }, [selectedSchedule]);

    const resetFields = () => {
        setFormData({
            id: -1,
            title: '',
            date: null,
            hourStart: '',
            hourEnd: '',
            trainerId: Number(localStorage.getItem('userId')),
            quantity: '',
            classId: '',
        });
        setErrors([]);
        setIsValidated(false);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    useEffect(() => {
        const addModalElement = document.getElementById('add-schedule');
        addModalElement?.addEventListener('hide.bs.modal', resetFields);
        return () => {
            addModalElement?.removeEventListener('hide.bs.modal', resetFields);
        };
    }, []);

    const handleConfirmCreate = async (event: React.FormEvent) => {
        event.preventDefault();

        const validationErrors = validateSchedule(formData, currentDate ? currentDate.toISOString().split('T')[0] : undefined);
        if (validationErrors.length > 0) {
            setErrors(validationErrors);
            return;
        }

        try {
            setServerError(null);
            if (formData.id === -1) {
                await apiRepository.createSchedule(formData);
            } else {
                await apiRepository.updateSchedule(formData.id, formData);
            }
            onUpdateSuccess?.();

            const closeModalButton = document.querySelector('#add-schedule [data-bs-dismiss="modal"]');
            if (closeModalButton) {
                closeModalButton.click();
            }
        } catch (error: any) {
            console.error('Error al guardar el horario:', error);
            const errorMessage = error.response?.data?.message || 'Error al procesar la solicitud.';
            setServerError(errorMessage);
        }
    };

    const getErrorForField = (field: any) => {
        const error = errors.find((error) => error.field === field);
        return error ? error.message : null;
    };

    return (
        <>
            <div className="modal fade" id="add-schedule">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Agregar Clase</h5>
                            <button
                                type="button"
                                className="btn-close close-modal"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                            <Icon.X className="react-feather-custom"></Icon.X>
                            </button>
                        </div>
                        <div className="modal-body pt-0">
                            {serverError && (
                                <div className="alert alert-danger" role="alert">
                                    {serverError}
                                </div>
                            )}
                            <form onSubmit={handleConfirmCreate}>
                                <InputField
                                    label="Título"
                                    name="title"
                                    value={formData.title}
                                    onChange={handleChange}
                                    error={getErrorForField('title')}
                                />
                                <InputField
                                    label="Cantidad"
                                    name="quantity"
                                    value={formData.quantity}
                                    onChange={handleChange}
                                    error={getErrorForField('quantity')}
                                />
                                <CalendarField
                                    label="Fecha"
                                    name="date"
                                    value={formData.date}
                                    onChange={(e) => setFormData((prevFormData) => ({
                                        ...prevFormData,
                                        date: e.value as Date,
                                    }))}
                                    error={getErrorForField('date')}
                                />
                                <DropdownField
                                    label="Clase"
                                    value={formData.classId}
                                    options={classes}
                                    onChange={(e) => setFormData(prevFormData => ({
                                        ...prevFormData,
                                        classId: e.value,
                                    }))}
                                    error={getErrorForField('classId')}
                                />
                                <TimePickerField
                                    label="Hora de Inicio"
                                    value={formData.hourStart}
                                    format="hh:mm a"
                                    onChange={(newValue: string) => setFormData(prevFormData => ({
                                        ...prevFormData,
                                        hourStart: newValue,
                                    }))}
                                    error={getErrorForField('hourStart')}
                                />
                                <TimePickerField
                                    label="Hora de Fin"
                                    value={formData.hourEnd}
                                    format="hh:mm a"
                                    onChange={(newValue: string) => setFormData(prevFormData => ({
                                        ...prevFormData,
                                        hourEnd: newValue,
                                    }))}
                                    error={getErrorForField('hourEnd')}
                                />
                                <div className="text-end">
                                    <button
                                        type="button"
                                        className="btn btn-light me-2"
                                        data-bs-dismiss="modal"
                                    >
                                        Cancelar
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
//                                        data-bs-dismiss={isValidated ? "modal" : undefined}
                                    >
                                        Crear Clase
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SchedulesModal
