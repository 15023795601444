import React, { useState, useEffect } from 'react';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import PagesAuthHeader from './common/header';
import { Link, useNavigate } from 'react-router-dom';
import { all_routes } from '../../../../core/data/routes/all_routes';
import AuthFooter from './common/footer';
import { InputField } from '../../../admin/common/input/inputField';
import { validateLogin, ValidationError, validateUser } from '../../../../core/form/validationForm';
import { handleChange } from '../../../../core/utils/utils';
import { apiRepository, Auth } from '../../../../api/apiRepository';

const Login2 = () => {
  const routes = all_routes;
  const navigate = useNavigate();
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const [formData, setFormData] = useState<Users>({
    email: '',
    password: '',
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    handleChange(e, setFormData);
  };

  const authLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    setErrors([]);
    const { email, password } = formData;
    const validationErrors = validateLogin(email, password);
    if (validationErrors.length > 0) {
      setErrors(validationErrors);
      return;
    }

    const user: Auth = { email, password};

    try {
      const response = await apiRepository.authUser(user);
      const { success, data, message } = response.data;
      if (success) {
        localStorage.setItem('authToken', data.authToken);
        localStorage.setItem('userId', data.id);
        localStorage.setItem('userName', data.name);
        localStorage.setItem('userProfile', data.profile);
        console.info('Usuario verificado correctamente');

        navigate(routes.index);
      } else {
        setErrors(message);
      }
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.message) {
        const cleanedMessage = error.response.data.message.replace(/^Bad request: /, '');
        setErrors(prevErrors => [
          ...prevErrors,
          { field: "server", message: cleanedMessage }
        ]);
      } else {
        setErrors(prevErrors => [
          ...prevErrors,
          { field: "server", message: 'Ocurrió un error al intentar iniciar sesión. Inténtalo de nuevo.' }
        ]);
      }
    }
  };


  return (
    <>
      <PagesAuthHeader />
      <div className="main-wrapper">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-5 mx-auto">
              <form onSubmit={authLogin}>
                <div className="d-flex flex-column justify-content-center">
                  <div className="card p-sm-4 my-5">
                    <div className="card-body">
                      <div className="text-center mb-3">
                        <h3 className="mb-2">Bienvenido a Lift</h3>
                        <p>Ingrese sus credenciales para acceder a su cuenta</p>
                      </div>
                      <InputField
                        label="Correo Electronico"
                        name='email'
                        value={formData.email}
                        onChange={handleInputChange}
                        error={errors.find((err) => err.field === 'email')?.message}
                        />
                        <div className="mb-3">
                          <div className="d-flex align-items-center justify-content-between flex-wrap">
                            <label className="form-label">Contraseña</label>
                            <Link
                              to={routes.forgotPassword}
                              className="text-primary fw-medium text-decoration-underline mb-1 fs-14"
                            >
                              ¿Olvidaste tu contraseña?
                            </Link>
                          </div>
                          <InputField
                              type="password"
                              name="password"
                              value={formData.password}
                              onChange={handleInputChange}
                              error={errors.find((err) => err.field === 'password')?.message}
                          />
                        </div>
                        <div className='mb-3'>
                          {errors.find(error => error.field === 'server') && (
                              <small className="text-danger mb-3">
                                {errors.find(error => error.field === 'server')?.message}
                              </small>
                          )}
                        </div>
                      {/*<div className="mb-3">*/}
                      {/*  <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-2">*/}
                      {/*    <div className="form-check">*/}
                      {/*      <input*/}
                      {/*        className="form-check-input"*/}
                      {/*        type="checkbox"*/}
                      {/*        defaultValue=""*/}
                      {/*        id="remember_me"*/}
                      {/*      />*/}
                      {/*      <label className="form-check-label" htmlFor="remember_me">*/}
                      {/*        Recordarme*/}
                      {/*      </label>*/}
                      {/*    </div>*/}
                      {/*    <div className="form-check">*/}
                      {/*      <input*/}
                      {/*        className="form-check-input"*/}
                      {/*        type="checkbox"*/}
                      {/*        defaultValue=""*/}
                      {/*        id="otp_signin"*/}
                      {/*      />*/}
                      {/*      <label className="form-check-label" htmlFor="otp_signin">*/}
                      {/*        Iniciar sesión con OTP*/}
                      {/*      </label>*/}
                      {/*    </div>*/}
                      {/*  </div>*/}
                      {/*</div>*/}
                      <div className="mb-3">
                        <button
                          type="submit"
                          className="btn btn-lg btn-linear-primary w-100"
                        >
                          Iniciar sesión
                        </button>
                      </div>
                      {/*<div className="login-or mb-3">*/}
                      {/*  <span className="span-or">O inicie sesión con</span>*/}
                      {/*</div>*/}
                      {/*<div className="d-flex align-items-center mb-3">*/}
                      {/*  <Link*/}
                      {/*    to="#"*/}
                      {/*    className="btn btn-light flex-fill d-flex align-items-center justify-content-center me-3"*/}
                      {/*  >*/}
                      {/*    <ImageWithBasePath*/}
                      {/*      src="assets/img/icons/google-icon.svg"*/}
                      {/*      className="me-2"*/}
                      {/*      alt="Img"*/}
                      {/*    />*/}
                      {/*    Google*/}
                      {/*  </Link>*/}
                      {/*  <Link*/}
                      {/*    to="#"*/}
                      {/*    className="btn btn-light flex-fill d-flex align-items-center justify-content-center"*/}
                      {/*  >*/}
                      {/*    <ImageWithBasePath*/}
                      {/*      src="assets/img/icons/fb-icon.svg"*/}
                      {/*      className="me-2"*/}
                      {/*      alt="Img"*/}
                      {/*    />*/}
                      {/*    Facebook*/}
                      {/*  </Link>*/}
                      {/*</div>*/}
                      <div className="d-flex justify-content-center">
                        <p>
                          ¿No tiene una cuenta?{" "}
                          <Link to={routes.userSignup} className="text-primary">
                            {" "}
                            Regístrese hoy
                          </Link>
                        </p>
                      </div>
                    </div>
                    <div>
                      <ImageWithBasePath
                        src="assets/img/bg/authentication-bg.png"
                        className="bg-left-top"
                        alt="Img"
                      />
                      <ImageWithBasePath
                        src="assets/img/bg/authentication-bg.png"
                        className="bg-right-bottom"
                        alt="Img"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <AuthFooter />
    </>
  );
};

export default Login2;