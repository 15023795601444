import React, { useState, useEffect } from 'react';
import * as Icon from 'react-feather';
import { apiRepository, Package } from '../../../../api/apiRepository';
import { validatePackage, ValidationError } from '../../../../core/form/validationForm';

export interface UpdatePackageModalProps {
    selectedPackage?: { id: number; title: string; price: string; category: number; packageTypeId: number; description: string; benefits: string[]; status: boolean };
    categoriesPackage?: [];
    onUpdateSuccess?: () => void;
}

const CouponsModal: React.FC<UpdatePackageModalProps> = ({ selectedPackage, onUpdateSuccess, categoriesPackage } ) => {
    const [id, setId] = useState<number>(selectedPackage ? selectedPackage.id : -1);
    const [title, setName] = useState<string>(selectedPackage ? selectedPackage.title : '');
    const [price, setPrice] = useState<string>(selectedPackage ? selectedPackage.price : '');
    const [category, setCategory] = useState<number>(selectedPackage ? selectedPackage.category : 0);
    const [packageTypeId, setPackageTypeId] = useState<number>(selectedPackage ? selectedPackage.packageTypeId : -1);
    const [description, setDescription] = useState<string>(selectedPackage ? selectedPackage.description : '');
    const [benefits, setBenefits] = useState<string[]>(selectedPackage ? selectedPackage.benefits : []);
    const [status, setStatus] = useState<boolean>(selectedPackage ? selectedPackage.status : true);
    const [errors, setErrors] = useState<ValidationError[]>([]);
    const [isValidated, setIsValidated] = useState(false);

    useEffect(() => {
        if (selectedPackage) {
            setId(selectedPackage.id);
            setName(selectedPackage.title);
            setPrice(selectedPackage.price);
            setPackageTypeId(selectedPackage.packageTypeId);
            setDescription(selectedPackage.description);
            setBenefits(selectedPackage.benefits);
            setStatus(selectedPackage.status);
        }
    }, [selectedPackage]);

    useEffect(() => {
        const validationErrors = validatePackage(title, price, packageTypeId, description);
        setIsValidated(validationErrors.length === 0);
    }, [title, price, category, description, benefits]);

    const resetFields = () => {
        setId(-1);
        setName('');
        setPrice('');
        setCategory(0);
        setPackageTypeId(-1);
        setDescription('');
        setBenefits([]);
        setStatus(true);
        setErrors([]);
        setIsValidated(false);
    };

    useEffect(() => {
        const addModalElement = document.getElementById('add-package');
        const editModalElement = document.getElementById('edit-package');

        addModalElement?.addEventListener('hide.bs.modal', resetFields);
        editModalElement?.addEventListener('hide.bs.modal', resetFields);

        return () => {
            addModalElement?.removeEventListener('hide.bs.modal', resetFields);
            editModalElement?.removeEventListener('hide.bs.modal', resetFields);
        };
    }, []);

    const handleConfirmCreate = async (event: React.FormEvent) => {
        event.preventDefault();

        const validationErrors = validatePackage(title, price, packageTypeId, description);
        if (validationErrors.length > 0) {
            setErrors(validationErrors);
            return;
        } else {
            setErrors([]);
        }

        const newPackage: Package = { id, title, price, packageTypeId, description, status };

        try {
            if (id === -1) {
                await apiRepository.createPackage(newPackage);
            } else {
                await apiRepository.updatePackage(id, newPackage);
            }
            if (onUpdateSuccess) onUpdateSuccess();
        } catch (error) {
            console.error("Error al guardar el cupón:", error);
        }
    };

    return (
        <>
            {/* Add Coupon */}
            <div className="modal fade" id="add-package">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Agregar Cupón</h5>
                            <button type="button" className="btn-close close-modal" data-bs-dismiss="modal" aria-label="Close">
                                <Icon.X className="react-feather-custom" />
                            </button>
                        </div>
                        <div className="modal-body pt-0">
                            <form onSubmit={handleConfirmCreate}>
                                <div className="mb-3">
                                    <label className="form-label">Titulo</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={title}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                    {errors.find(error => error.field === 'title') && (
                                        <small className="text-danger">
                                            {errors.find(error => error.field === 'title')?.message}
                                        </small>
                                    )}
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Precio</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        value={price}
                                        onChange={(e) => setPrice(String(e.target.value))}
                                        step="0.01"
                                    />
                                    {errors.find(error => error.field === 'price') && (
                                        <small className="text-danger">
                                            {errors.find(error => error.field === 'price')?.message}
                                        </small>
                                    )}
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Categoría</label>
                                    <select
                                        className="form-control"
                                        value={packageTypeId}
                                        onChange={(e) => setPackageTypeId(Number(e.target.value))}
                                    >
                                    <option value="">Seleccione una categoría</option>
                                    {categoriesPackage.map((packageTypeId, index) => (
                                        <option key={index} value={packageTypeId.id}>{packageTypeId.name}</option>
                                    ))}
                                    </select>
                                    {errors.find(error => error.field === 'categorie') && (
                                        <small className="text-danger">
                                            {errors.find(error => error.field === 'categorie')?.message}
                                        </small>
                                    )}
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Descripción</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                </div>
                                {errors.find(error => error.field === 'description') && (
                                    <small className="text-danger">
                                        {errors.find(error => error.field === 'description')?.message}
                                    </small>
                                )}
                                {/* <div className="mb-3">
                                    <label className="form-label">Beneficios</label>
                                    {benefits.map((benefit, index) => (
                                        <input
                                            key={index}
                                            type="text"
                                            className="form-control mb-2"
                                            value={benefit}
                                            onChange={(e) => {
                                                const newBenefits = [...benefits];
                                                newBenefits[index] = e.target.value;
                                                setBenefits(newBenefits);
                                            }}
                                            placeholder={`Beneficio ${index + 1}`}
                                        />
                                    ))}
                                </div> */}
                                <div className="text-end">
                                    <button type="button" className="btn btn-secondary me-2" data-bs-dismiss="modal">
                                        Cancelar
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        data-bs-dismiss={isValidated ? "modal" : undefined}
                                    >
                                        Guardar
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Add Coupon */}
            {/* Edit Package */}
            <div className="modal fade" id="edit-package">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Editar Cupón</h5>
                            <button type="button" className="btn-close close-modal" data-bs-dismiss="modal" aria-label="Close">
                                <Icon.X className="react-feather-custom" />
                            </button>
                        </div>
                        <div className="modal-body pt-0">
                        <form onSubmit={handleConfirmCreate}>
                                <div className="mb-3">
                                    <label className="form-label">Titulo</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={title}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                    {errors.find(error => error.field === 'title') && (
                                        <small className="text-danger">
                                            {errors.find(error => error.field === 'title')?.message}
                                        </small>
                                    )}
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Precio</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={price}
                                        onChange={(e) => setPrice(String(e.target.value))}
                                    />
                                    {errors.find(error => error.field === 'price') && (
                                        <small className="text-danger">
                                            {errors.find(error => error.field === 'price')?.message}
                                        </small>
                                    )}
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Categoría</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        defaultValue={ categoriesPackage.find(category => category.id === packageTypeId) ?.name}
                                        readOnly
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Descripción</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                    {errors.find(error => error.field === 'description') && (
                                        <small className="text-danger">
                                            {errors.find(error => error.field === 'description')?.message}
                                        </small>
                                    )}
                                </div>
                                {/* <div className="mb-3">
                                    <label className="form-label">Beneficios</label>
                                    {benefits.map((benefit, index) => (
                                        <input
                                            key={index}
                                            type="text"
                                            className="form-control mb-2"
                                            value={benefit}
                                            onChange={(e) => {
                                                const newBenefits = [...benefits];
                                                newBenefits[index] = e.target.value;
                                                setBenefits(newBenefits);
                                            }}
                                            placeholder={`Beneficio ${index + 1}`}
                                        />
                                    ))}
                                </div> */}
                                <div className="text-end">
                                    <button type="button" className="btn btn-secondary me-2" data-bs-dismiss="modal">
                                        Cancelar
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        data-bs-dismiss={isValidated ? "modal" : undefined}
                                    >
                                        Guardar Cambios
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Edit Package */}
        </>
    )
}

export default CouponsModal
