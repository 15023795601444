import { useEffect } from 'react';

export function useLocalStorageListener(key, callback) {
  useEffect(() => {
    const originalSetItem = localStorage.setItem;

    localStorage.setItem = function (k, value) {
      originalSetItem.call(this, k, value);
      if (k === key) {
        callback(value);
      }
    };

    return () => {
      localStorage.setItem = originalSetItem;
    };
  }, [key, callback]);
}
