export const packageData = [
    {
        id: 1,
        nombre: "1 clase",
        features: [
          "Evaluación inicial personalizada",
          "Acceso a entrenamiento grupal",
          "Asesoramiento en nutrición básica"
        ],
        precio: "S/65",
        per: "POR PAQUETE COMPLETO"
    },
    {
        id: 2,
        nombre: "5 clases",
        features: [
          "Evaluación inicial personalizada",
          "Acceso a entrenamiento grupal",
          "Asesoramiento en nutrición básica"
        ],
        precio: "S/305",
        per: "POR PAQUETE COMPLETO"
    },
    {
        id: 3,
        nombre: "8 clases",
        features: [
        "Evaluación inicial personalizada",
        "Acceso a entrenamiento grupal",
        "Asesoramiento en nutrición básica"
        ],
        precio: "S/470",
        per: "POR PAQUETE COMPLETO",
        popular: true
    },
    {
        id: 4,
        nombre: "12 clases",
        features: [
            "Evaluación inicial personalizada",
            "Acceso a entrenamiento grupal",
            "Asesoramiento en nutrición básica"
        ],
        precio: "S/690",
        per: "POR PAQUETE COMPLETO"
    },

];