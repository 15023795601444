import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import { packageData } from '../../../../core/data/json/packageData';
import { apiRepository } from '../../../../api/apiRepository';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PreferredSection = () => {
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await apiRepository.getPackage();
        console.debug('response')
        console.debug(response, 'response - `pst')
        const filteredPackages = response.data.data.content.filter((pkg) => pkg.packageTypeId === 5);
        console.debug(filteredPackages, 'filteredPackages')
        setPackages(filteredPackages);
      } catch (error) {
        console.error("Error al obtener los paquetes:", error);
      }
    };

    fetchPackages();
  }, []);

  return (
    <section className="section ">
      <div className="container col-11">
        <div className="section-heading section-heading-six">
          <div className="row justify-content-center text-center">
            <div className="col-md-9 aos">
              <h2>
                Escoge tu Paquete
              </h2>
            </div>
          </div>
        </div>
        <div className="row home-package justify-content-center">
        {packages.map((pkg: any, index: number) => (
  <div className="col-lg-3 col-md-6 col-12" key={index}>
    <div className={`hidden-charge-main pricing-plans price-new ${index === 2 ? "hidden-charge-main-two" : ""}`}>
      <h4>{pkg.title}</h4>
      <div className="pricing-planscontent col-lg-12">
        <ul>
          {packageData[0].features.map((feature: any, i: any) => (
            <li key={i}>
              <i className="fa fa-check-circle me-2" />
              <span>{feature}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className="hidden-charges-bottom-mark text-center">
        <h1>S/ {pkg.price}</h1>
        <h6>{pkg.description}</h6>
      </div>
      <Link to="#" className={`btn w-100 ${index === 2 ? "btn-package" : ""}`}>
        Reservar ahora
      </Link>
    </div>
  </div>
))}

        </div>
      </div>
    </section>
  );
};

export default PreferredSection