import { DataTable } from 'primereact/datatable';
import React, { useState, useEffect } from 'react';
import { Column } from 'primereact/column';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import PackageModal from '../common/modals/package-modal';
import DeletePackageModal from '../common/modals/delete-packages-modal';
import { apiRepository, Package, packageTypes } from '../../../api/apiRepository';

const Packages = () => {
    const [data, setData] = useState<Package[]>([]);
    const [categories, setCategories] = useState<packageTypes[]>([]);
    const [selectedPackageId, setSelectedPackageId] = useState<number>(-1);
    const [selectedPackage, setSelectedPackage] = useState<string | null>(null);

    const fetchPackages = async () => {
        try {
            const response = await apiRepository.getPackage();
            setData(response.data.data);
        } catch (error) {
            console.error("Error al obtener los datos de paquetes:", error);
        }
    };

    const fetchCategories = async () => {
        try {
            const response = await apiRepository.getPackageTypes();
            setCategories(response.data.data);
        } catch (error) {
            console.error("Error al obtener los datos de categorías:", error);
        }
    };

    useEffect(() => {
        fetchPackages();
        fetchCategories();
    }, []);

    const handleUpdateSuccess = () => {
        fetchPackages();
    };

    const categoryNameBodyTemplate = (rowData: any) => {
        const category = categories.find(cat => cat.id === rowData.packageTypeId);
        return category ? category.name : "Desconocido";
    };

    const priceBodyTemplate = (rowData: any) => {
        if (rowData.price && !isNaN(rowData.price)) {
            return `S/ ${rowData.price.toFixed(2)}`;
        } else {
            return 'S/ 0.00';
        }
    };

    const actionButton = (rowData: any) => {
        const { id, title, price, description, status, packageTypeId } = rowData;

        const handleEditClick = () => {
            setSelectedPackage({ id, title, price, description, status, packageTypeId });
        };

        const handleDeleteClick = () => {
            setSelectedPackageId(id);
        };

        return (
            <div className="table-actions d-flex">
                <Link
                    className="delete-table me-2"
                    onClick={handleEditClick}
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#edit-package"
                >
                    <Icon.Edit className="react-feather-custom" />
                </Link>
                <Link
                    className="delete-table"
                    onClick={handleDeleteClick}
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#delete-package"
                >
                    <Icon.Trash2 className="react-feather-custom" />
                </Link>
            </div>
        );
    };

    return (
        <>
            <div className="page-wrapper page-settings">
                <div className="content">
                    <div className="content-page-header content-page-headersplit">
                        <h5>Paquetes</h5>
                        <div className="list-btn">
                            <ul>
                                <li>
                                    <button
                                        className="btn btn-primary"
                                        type="button"
                                        data-bs-toggle="modal"
                                        data-bs-target="#add-package"
                                    >
                                        <i className="fa fa-plus me-2" />
                                        Agregar Paquete
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="table-resposnive">
                                <DataTable
                                    paginatorTemplate="RowsPerPageDropdown CurrentPageReport PrevPageLink PageLinks NextPageLink"
                                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                                    value={data}
                                    showGridlines
                                    tableStyle={{ minWidth: '50rem' }}
                                >
                                    <Column sortable field="title" header="Titulo" />
                                    <Column sortable field="price" header="Precio" body={priceBodyTemplate} />
                                    <Column sortable field="description" header="Descripción" />
                                    <Column sortable field="packageTypeId" header="Categoría" body={categoryNameBodyTemplate} />
                                    <Column header="Acción" body={actionButton} />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PackageModal selectedPackage={selectedPackage} onUpdateSuccess={handleUpdateSuccess} categoriesPackage={categories}/>
            <DeletePackageModal selectedPackageId={selectedPackageId} onDeleteSuccess={handleUpdateSuccess} />
        </>
    );
};

export default Packages;
