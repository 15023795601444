import React from 'react';
import { apiRepository } from '../../../../api/apiRepository';
import * as Icon from 'react-feather';

export interface DeleteFaqModalProps {
    selectedId: number;
    onDeleteSuccess?: () => void;
}

const DeleteFaqModal: React.FC<DeleteFaqModalProps> = ({ selectedId, onDeleteSuccess }) => {

    const handleConfirmDelete = async () => {
        if (selectedId === -1) return;

        try {
            console.log("Intentando eliminar FAQ con ID:", selectedId);
            await apiRepository.deleteFaq(selectedId);
            console.log("FAQ eliminada con éxito");

            if (onDeleteSuccess) {
                onDeleteSuccess();
            }
        } catch (error) {
            console.error("Error al eliminar FAQ:", error);
        }
    };

    return (
        <div>
            <div className="modal fade" id="delete-faq">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body pt-0">
                            <div className="text-center">
                                <Icon.Trash2 size={45} color='#f05050' className="text-danger fs-1" />
                                <div className="mt-4">
                                    <h4>Eliminar Pregunta?</h4>
                                    <p className="text-muted mb-0">¿Estas seguro que quieres eliminar esta pregunta?</p>
                                </div>
                            </div>
                            <div className="d-flex gap-2 justify-content-center mt-4">
                                <button
                                    type="button"
                                    className="btn w-sm btn-secondary"
                                    data-bs-dismiss="modal"
                                >
                                    Cancelar
                                </button>
                                <button
                                    type="button"
                                    className="btn w-sm btn-danger"
                                    data-bs-dismiss="modal"
                                    onClick={handleConfirmDelete}
                                >
                                    Si, Borrrar Pregunta
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DeleteFaqModal;
