export const servicesDetailData = {
  id: 1,
  title: "Strength",
  description: "Esculpe tu cuerpo, potencia tu fuerza y mejora tu técnica al levantar pesas bajo la cuidadosa instrucción de la entrenadora. sculpe tu cuerpo, potencia tu fuerza y mejora tu técnica al levantar pesas bajo la cuidadosa instrucción de la entrenadora. sculpe tu cuerpo, potencia tu fuerza y mejora tu técnica al levantar pesas bajo la cuidadosa instrucción de la entrenadora. sculpe tu cuerpo, potencia tu fuerza y mejora tu técnica al levantar pesas bajo la cuidadosa instrucción de la entrenadora. sculpe tu cuerpo, potencia tu fuerza y mejora tu técnica al levantar pesas bajo la cuidadosa instrucción de la entrenadora.",
  price: 25.00,
  schedule: {
    monday: "08:00 - 09:00",
    tuesday: "18:00 - 19:00",
    wednesday: "08:00 - 09:00",
    thursday: "18:00 - 19:00",
    friday: "08:00 - 09:00"
  },
  benefits: [
    "Incrementa la fuerza muscular",
    "Mejora la técnica de levantamiento",
    "Aumenta la resistencia física"
  ],
  rating: "4.5",
  videoUrl: "https://www.example.com/videos/strength-demo.mp4",
  slider: [
    { id: 1, image: "assets/img/services/service-slider-01.jpg" },
    { id: 2, image: "assets/img/services/service-slider-02.jpg" },
    { id: 3, image: "assets/img/services/service-slider-03.jpg" }
  ],
  previews: [
    { id: 1, image: "assets/img/services/service-thumb-01.jpg" },
    { id: 2, image: "assets/img/services/service-thumb-02.jpg" },
    { id: 3, image: "assets/img/services/service-thumb-03.jpg" }
  ]
};