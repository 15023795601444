import React, { useState, useEffect } from 'react';
import * as Icon from 'react-feather';
import { apiRepository } from '../../../../api/apiRepository';
import { validateClasses, ValidationError } from '../../../../core/form/validationForm';
import { Link } from 'react-router-dom';

export interface UpdatePackageModalProps {
    selectedClasses?: { id: number; title: string; description: string; image: string; status: boolean };
    onUpdateSuccess?: () => void;
}

const ClassesModal: React.FC<UpdatePackageModalProps> = ({ selectedClasses, onUpdateSuccess }) => {
    const [id, setId] = useState<number>(selectedClasses ? selectedClasses.id : -1);
    const [title, setName] = useState<string>(selectedClasses ? selectedClasses.title : '');
    const [description, setDescription] = useState<string>(selectedClasses ? selectedClasses.description : '');
    const [image, setImage] = useState<string>(selectedClasses ? selectedClasses.image : '');
    const [file, setFile] = useState<File | null>(null);
    const [status, setStatus] = useState<boolean>(selectedClasses ? selectedClasses.status : true);
    const [errors, setErrors] = useState<ValidationError[]>([]);
    const [isValidated, setIsValidated] = useState(false);

    useEffect(() => {
        if (selectedClasses) {
            setId(selectedClasses.id);
            setName(selectedClasses.title);
            setDescription(selectedClasses.description);
            setImage(selectedClasses.image);
            setStatus(selectedClasses.status);
        }
    }, [selectedClasses]);

    useEffect(() => {
        const validationErrors = validateClasses(title, description);
        setIsValidated(validationErrors.length === 0);
    }, [title, description]);

    const resetFields = () => {
        setId(-1);
        setName('');
        setDescription('');
        setFile(null)
        setImage('');
        setStatus(true);
        setErrors([]);
        setIsValidated(false);
    };

    useEffect(() => {
        const addModalElement = document.getElementById('add-classes');
        const editModalElement = document.getElementById('edit-classes');

        addModalElement?.addEventListener('hide.bs.modal', resetFields);
        editModalElement?.addEventListener('hide.bs.modal', resetFields);

        return () => {
            addModalElement?.removeEventListener('hide.bs.modal', resetFields);
            editModalElement?.removeEventListener('hide.bs.modal', resetFields);
        };
    }, []);

    const handleConfirmCreate = async (event: React.FormEvent) => {
        event.preventDefault();

        const validationErrors = validateClasses(title, description);
        if (validationErrors.length > 0) {
            setErrors(validationErrors);
            return;
        } else {
            setErrors([]);
        }

        console.debug(file)

        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('path', 'example');
        formData.append('status', status);
        formData.append('file', file);


        try {
            if (id === -1) {
                await apiRepository.createPackage(formData);
            } else {
                await apiRepository.updatePackage(id, formData);
            }
            const response = await apiRepository.createClasses(formData);
            console.info('Clase creada exitosamente');
            if (onUpdateSuccess) onUpdateSuccess();
          } catch (error) {
            console.error('Error al crear la clase:', error);
          }
        };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
        setFile(event.target.files[0]);
        }
    };

    return (
        <>
            {/* Add Package */}
            <div className="modal fade" id="add-classes">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Agregar Clase</h5>
                            <button type="button" className="btn-close close-modal" data-bs-dismiss="modal" aria-label="Close">
                                <Icon.X className="react-feather-custom" />
                            </button>
                        </div>
                        <div className="modal-body pt-0">
                            <form onSubmit={handleConfirmCreate}>
                                <div className="mb-3">
                                    <label className="form-label">Titulo</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={title}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                    {errors.find(error => error.field === 'title') && (
                                        <small className="text-danger">
                                            {errors.find(error => error.field === 'title')?.message}
                                        </small>
                                    )}
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Descripción</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                    {errors.find(error => error.field === 'description') && (
                                        <small className="text-danger">
                                            {errors.find(error => error.field === 'description')?.message}
                                        </small>
                                    )}
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Imagen de Clase</label>
                                    <div className="form-uploads">
                                        <div className="form-uploads-path mb-3">
                                            <Icon.Image className="react-feather-custom" />
                                            <div className="file-browse col-12">
                                                <h6>Selecciona la imagen de la clase en tu</h6>
                                                <div className="file-browse-path">
                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={handleFileChange}
                                                        className="col-12" />
                                                    <Link to="#">archivos</Link>
                                                </div>
                                            </div>
                                            <h5>Supported formates: JPEG, PNG</h5>
                                        </div>
                                        {file && (
                                            <div className='d-flex'>
                                                <img
                                                    className="img-fluid shadow rounded"
                                                    src={URL.createObjectURL(file)}
                                                    alt="Vista previa"
                                                    style={{ width: 'auto', height: '100px' }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="text-end">
                                    <button type="button" className="btn btn-secondary me-2" data-bs-dismiss="modal">
                                        Cancelar
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        data-bs-dismiss={isValidated ? "modal" : undefined}
                                    >
                                        Guardar
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Add Package */}
            {/* Edit Package */}
            <div className="modal fade" id="edit-classes">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Editar Clase</h5>
                            <button type="button" className="btn-close close-modal" data-bs-dismiss="modal" aria-label="Close">
                                <Icon.X className="react-feather-custom" />
                            </button>
                        </div>
                        <div className="modal-body pt-0">
                            <form onSubmit={handleConfirmCreate}>
                                <div className="mb-3">
                                    <label className="form-label">Titulo</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={title}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                    {errors.find(error => error.field === 'title') && (
                                        <small className="text-danger">
                                            {errors.find(error => error.field === 'title')?.message}
                                        </small>
                                    )}
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Descripción</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                    {errors.find(error => error.field === 'description') && (
                                        <small className="text-danger">
                                            {errors.find(error => error.field === 'description')?.message}
                                        </small>
                                    )}
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Imagen de Clase</label>
                                    <div className="form-uploads">
                                        <div className="form-uploads-path mb-3">
                                            <Icon.Image className="react-feather-custom" />
                                            <div className="file-browse col-12">
                                                <h6>Selecciona la imagen de la clase en tu</h6>
                                                <div className="file-browse-path">
                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={handleFileChange}
                                                        className="col-12" />
                                                    <Link to="#">archivos</Link>
                                                </div>
                                            </div>
                                            <h5>Supported formates: JPEG, PNG</h5>
                                        </div>
                                        {(file || image) && (
                                            <div className='d-flex'>
                                                <img
                                                    className="img-fluid shadow rounded"
                                                    src={file ? URL.createObjectURL(file) : `http://95.217.211.66:8080${image}`}
                                                    alt="Vista previa"
                                                    style={{ width: 'auto', height: '100px' }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="text-end">
                                    <button type="button" className="btn btn-secondary me-2" data-bs-dismiss="modal">
                                        Cancelar
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        data-bs-dismiss={isValidated ? "modal" : undefined}
                                    >
                                        Guardar Cambios
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Edit Package */}
        </>
    );
}

export default ClassesModal;