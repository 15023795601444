import axios, { AxiosInstance } from "axios";
import { API_BASE_URL } from '../environment';

const handleResponseError = (error: any): Promise<any> => {
    if (error.response) {
      console.error(`Error ${error.response.status}: ${error.response.data}`);
    } else if (error.request) {
      console.error("No response received:", error.request);
    } else {
      console.error("Error setting up request:", error.message);
    }
    return Promise.reject(error);
  };

export const api: AxiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 5000, // 5 segundos
});

api.interceptors.response.use(
  (response) => response,
  handleResponseError
);
