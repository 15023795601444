import React, { useState, useEffect } from 'react';
import * as Icon from 'react-feather';
import { apiRepository, packageTypes } from '../../../../api/apiRepository';
import { validateCategory, ValidationError } from '../../../../core/form/validationForm';

export interface UpdateCategoryModalProps {
    selectedCategory: { id: number; name: string, status: boolean };
    onUpdateSuccess?: () => void;
}

const CategoriesModal: React.FC<UpdateCategoryModalProps> = ({ selectedCategory, onUpdateSuccess }) => {
    const [id, setId] = useState<number>(selectedCategory ? selectedCategory.id : -1);
    const [name, setName] = useState<string>(selectedCategory ? selectedCategory.name : '');
    const [errors, setErrors] = useState<ValidationError[]>([]);
    const [isValidated, setIsValidated] = useState(false);

    useEffect(() => {
        if (selectedCategory) {
            setId(selectedCategory.id);
            setName(selectedCategory.name);
        }
    }, [selectedCategory]);

    useEffect(() => {
        const validationErrors = validateCategory(name);
        setIsValidated(validationErrors.length === 0);
    }, [name]);

    const resetFields = () => {
        console.debug(id)
        setId(-1);
        setName('');
        setErrors([]);
        setIsValidated(false);
    };

    useEffect(() => {
        const addModalElement = document.getElementById('add-category');
        const editModalElement = document.getElementById('edit-category');

        addModalElement?.addEventListener('hide.bs.modal', resetFields);
        editModalElement?.addEventListener('hide.bs.modal', resetFields);

        return () => {
            addModalElement?.removeEventListener('hide.bs.modal', resetFields);
            editModalElement?.removeEventListener('hide.bs.modal', resetFields);
        };
    }, []);

    const handleConfirmCreate = async (event: React.FormEvent) => {
        event.preventDefault();

        const validationErrors = validateCategory(name);
        if (validationErrors.length > 0) {
            setErrors(validationErrors);
            return;
        } else {
            setErrors([]);
        }

        const newCategory: packageTypes = { id: id, name, status };

        try {
            if (id === -1) {
                await apiRepository.createPackageTypes(newCategory);
            } else {
                await apiRepository.updatePackageTypes(id, newCategory);
            }
            console.log('Categoría modificada!');
            if (onUpdateSuccess) onUpdateSuccess();
        } catch (error) {
            console.error("Error al crear la categoría:", error);
        }
    };

    return (
        <>
            {/* Add Category */}
            <div className="modal fade" id="add-category">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Agregar Categoría</h5>
                            <button type="button" className="btn-close close-modal" data-bs-dismiss="modal" aria-label="Close">
                                <Icon.X className="react-feather-custom" />
                            </button>
                        </div>
                        <div className="modal-body pt-0">
                            <form onSubmit={handleConfirmCreate}>
                                <div className="mb-3">
                                    <label className="form-label">Nombre de la Categoría</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                    {errors.find(error => error.field === 'name') && (
                                        <small className="text-danger">
                                            {errors.find(error => error.field === 'name')?.message}
                                        </small>
                                    )}
                                </div>
                                <div className="text-end">
                                    <button type="button" className="btn btn-secondary me-2" data-bs-dismiss="modal">
                                        Cancelar
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        data-bs-dismiss={isValidated ? "modal" : undefined}
                                    >
                                        Guardar
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Add Category */}

            {/* Edit Category */}
            <div className="modal fade" id="edit-category">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Editar Categoría</h5>
                            <button type="button" className="btn-close close-modal" data-bs-dismiss="modal" aria-label="Close">
                                <Icon.X className="react-feather-custom" />
                            </button>
                        </div>
                        <div className="modal-body pt-0">
                            <form onSubmit={handleConfirmCreate}>
                                <div className="mb-3">
                                    <label className="form-label">Nombre de la Categoría</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                    {errors.find(error => error.field === 'name') && (
                                        <small className="text-danger">
                                            {errors.find(error => error.field === 'name')?.message}
                                        </small>
                                    )}
                                </div>
                                <div className="text-end">
                                    <button type="button" className="btn btn-secondary me-2" data-bs-dismiss="modal">
                                        Cancelar
                                    </button>
                                    <button type="submit" className="btn btn-primary" data-bs-dismiss={isValidated ? "modal" : undefined}>
                                        Guardar Cambios
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Edit Category */}
        </>
    );
};

export default CategoriesModal;
