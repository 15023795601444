import React from 'react'
import QuoteModal from '../../common/modals/quote-modal'
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath'
import BecomeProvider from '../../common/modals/provider-modal'
import { Link } from 'react-router-dom'
import { all_routes } from '../../../../core/data/routes/all_routes'
import FeatureSection from './feature-section'
import MainFooter from '../footer/main-footer';
import TrainerSection from './trainerSection'
import PackageSection from './packageSection'
import SuscriptionSection from './suscriptionSection'
import HomeHeader from '../header/main-header'
import AuthModals from '../header/authModals'
import Slider from 'react-slick';

const NewHome = () => {
  const routes = all_routes
  const imgslideroption = {
    dots: false,
    nav: false,
    infinite: true,
    speed: 500,
    swipe:true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
    <HomeHeader type={1}/>
    <>
  {/* Hero Section */}
  <Slider {...imgslideroption} className="img-slider home-sliders  nav-center hero-section">
    <div className="slide-images">
      <div className="hero-content position-relative overflow-hidden">
          <div className="hero-image slide-images">
              <ImageWithBasePath src="assets/lift/main-hero.jpg" alt="img" className="shape-03" />
          </div>
          <div className="container hero-action d-flex">
            <div className="action-content col-md-6 col-xxl-8 col-xl-12 col-lg-12 text-left justify-content-center">
              <h1 className="mb-2">
              UN ESPACIO PARA MUJERES, GUIADO POR EXPERTAS
              </h1>
              <p>El primer centro en Perú especializado en la fisiología de la mujer, pensado para tu salud y estética</p>
              <div className="mb-2 appointment-btn">
                <Link  to={routes.search} className="btn btn-primary d-inline-flex align-items-center ">
                  Ver todas las clases
                </Link>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div className="slide-images">
      <div className="hero-content position-relative overflow-hidden">
          <div className="hero-image slide-images">
              <ImageWithBasePath src="assets/lift/main-hero.jpg" alt="img" className="shape-03" />
          </div>
          <div className="container hero-action d-flex">
            <div className="action-content col-md-6 col-xxl-8 col-xl-12 col-lg-12 text-left justify-content-center">
              <h1 className="mb-2">
              UN ESPACIO PARA MUJERES, GUIADO POR EXPERTAS
              </h1>
              <p>El primer centro en Perú especializado en la fisiología de la mujer, pensado para tu salud y estética</p>
              <div className="mb-2 appointment-btn">
                <Link  to={routes.search} className="btn btn-primary d-inline-flex align-items-center ">
                  Ver todas las clases
                </Link>
              </div>
          </div>
        </div>
      </div>
    </div>

  </Slider>
  {/* /Hero Section */}

  <PackageSection/>

  {/* Trial Section */}
  <section className="section service-section">
    <div className="container col-11">
      <div className="row mt-4 align-items-center">
        <div className="col-md-6 col-12">
          <div className="appimg aos">
            <ImageWithBasePath
              src="assets/img/lift/free-trial.png"
              className="img-fluid"
              alt="img"
            />
          </div>
        </div>
        <div className="mt-4 col-md-6 col-12">
          <div className="download-sec trial-section">
            <div className="section-heading-two">
              <p>¡Prueba gratuita de nuestras sesiones!</p>
              <h2>Descubre tu mejor versión con una prueba gratuita</h2>
            </div>
            <div className="heading aos">
              <p>
              Somos un centro de entrenamiento enfocado en la fisiología de la mujer. Entrenadoras con más de 10 años de experiencia te guiarán para alcanzar tus objetivos de salud y estética.
              </p>
              <h6>¡Reserva tu prueba gratuita y da el primer paso hacia tus metas!</h6>
              {/* <div className="scan-img">
                <ImageWithBasePath
                  src="assets/img/scan-img.png"
                  className="img-fluid"
                  alt="img"
                />
              </div> */}
            </div>
            <div className="downlaod-btn aos">
            <div className="pricing-plans price-new">
              <Link  to={routes.search} className="btn">
                Reserva Gratis
              </Link>
            </div>
            <Link  to={routes.search} className="btn bg-primary-transparent ">
              Nuestras Entrenadoras
            </Link>
            {/* <Link  to={routes.search} className="btn btn-white ">
              Nuestras Entrenadoras
            </Link> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* /Trial Section */}

  <FeatureSection/>
  {/*<TrainerSection/>*/}
  <SuscriptionSection/>
  <MainFooter/>
</>
<AuthModals/>
<QuoteModal/>
<BecomeProvider/>
    </>
  )
}

export default NewHome