import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import DefaultEditor from 'react-simple-wysiwyg';
import { Link } from 'react-router-dom';
import { apiRepository, Pages } from '../../../api/apiRepository';

const EditLegal = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get('id');
  const [title, setTitle] = React.useState('');
  const [description, setDescription] = React.useState('');

  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await apiRepository.getPage(id);
        if (response) {
          setDescription(response.data.data.description);
          // Establecer el título según el valor del id
          if (id === "1") {
            setTitle("Terminos y Condiciones");
          } else if (id === "2") {
            setTitle("Politica de Privacidad");
          } else {
            setTitle(response.data.data.title); // Usar el título del servidor si el id no es 1 o 2
          }
        }
      } catch (error) {
        console.error('Error al cargar los datos:', error);
      }
    };
    loadData();
  }, [id]);

  function onChange(e: any) {
    setDescription(e.target.value);
  }

  const handleUpdate = async () => {
    const Pages = { id, description };

    try {
      await apiRepository.updatePage(id, Pages);
    } catch (error) {
      console.error('Error al actualizar los datos:', error);
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="row">
          <div className="col-lg-7 col-sm-12 m-auto">
            <div className="content-page-header">
              <h5>Editar términos legales</h5>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group">
                  <label>Título</label>
                  <input
                    type="text"
                    className="form-control"
                    value={title}
                    readOnly
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group">
                  <label> Descripción</label>
                  <div id="editor">
                    <DefaultEditor value={description} onChange={onChange} />
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="btn-path">
                  <Link to="#" className="btn btn-cancel me-3">
                    Cancelar
                  </Link>
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={handleUpdate}
                  >
                    Guardar Cambios
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditLegal;