import React from 'react';
import CountUp from 'react-countup';

interface DashWidgetProps {
  iconClass: string;
  bgClass: string;
  title: string;
  value: string | number;
  duration: number;
  hourStart?: string;
  hourEnd?: string;
}

const DashWidget: React.FC<DashWidgetProps> = ({
    iconClass,
    bgClass,
    title,
    value,
    duration,
    hourStart,
    hourEnd,
  }) => {
    return (
      <div className="col-xxl-4 col-md-6">
        <div className="card dash-widget">
          <div className="card-body d-flex justify-content-between align-items-start">
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <span
                  className={`dash-icon ${bgClass} d-flex justify-content-center align-items-center rounded-circle`}
                >
                  <i className={iconClass} />
                </span>
                <div className="ms-2">
                  <span className="fs-14">{title}</span>
                  <h5 className="text-capitalize">
                    {' '}
                    <span className="counter">
                      {typeof value === 'number' ? (
                        <CountUp end={value} duration={duration} />
                      ) : (
                        value
                      )}
                    </span>
                  </h5>
                </div>
              </div>
            </div>
            {hourStart && hourEnd && (
            <div className="d-flex justify-content-between mt-1">
              <div className="text-end">
                <span className="fs-12 d-block">{hourStart}</span>
                <span className="fs-12 d-block">{hourEnd}</span>
              </div>
            </div>
            )}
          </div>
        </div>
      </div>

    );
  };

export default DashWidget;