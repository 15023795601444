import React, { useEffect, useState } from 'react';
import BreadCrumb from '../../common/breadcrumb/breadCrumb';
import AOS from 'aos';
import { apiRepository } from '../../../../api/apiRepository';

const PrivacyPolicy = () => {
  const [termsContent, setTermsContent] = useState('');

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });

    const fetchPolicyData = async () => {
      try {
        const id = '1';
        const response = await apiRepository.getPage(id);
        if (response && response.data && response.data.data) {
          setTermsContent(response.data.data.description);
        }
      } catch (error) {
        console.error('Error al cargar la terminos y condiciones:', error);
      }
    };

    fetchPolicyData();
  }, []);

  return (
    <>
      {/* Breadcrumb */}
      <BreadCrumb title="Terminos y Condiciones" item1="Home" item2="Terminos y Condiciones" />
      {/* /Breadcrumb */}
      <div className="page-wrapper">
        <div className="content">
          <div className="container">
            <div className="row">
              {/* Terms & Conditions */}
              <div className="col-md-12">
                <div className="terms-content privacy-cont">
                  <div dangerouslySetInnerHTML={{ __html: termsContent }} />
                </div>
              </div>
              {/* /Terms & Conditions */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;