/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import { useSelector } from 'react-redux';
import CouponsModal from '../common/modals/coupons-modal';
import DeleteCouponsModal from '../common/modals/delete-coupons-modal';
import { CouponsInterface } from '../../../core/models/interface';
import { apiRepository, Coupon, packageTypes } from '../../../api/apiRepository';

const Coupons = () => {
    const [data, setData] = useState<Coupon[]>([]);
    const [categories, setCategories] = useState<packageTypes[]>([]);
    const [selectedCouponId, setSelectedCouponId] = useState<number>(-1);
    const [selectedCoupon, setSelectedCoupon] = useState<string | null>(null);

    const fetchCoupon = async () => {
        try {
            const response = await apiRepository.getCoupons();
            setData(response.data.data);
        } catch (error) {
            console.error("Error al obtener los datos de cupones:", error);
        }
    };

    const fetchCategories = async () => {
        try {
            const response = await apiRepository.getPackageTypes();
            setCategories(response.data.data);
        } catch (error) {
            console.error("Error al obtener los datos de categorías:", error);
        }
    };

    useEffect(() => {
        fetchCoupon();
        fetchCategories();
    }, []);

    const handleUpdateSuccess = () => {
        fetchCoupon();
    };

    const categoryNameBodyTemplate = (rowData: any) => {
        const category = categories.find(cat => cat.id === rowData.packageTypeId);
        return category ? category.name : "Desconocido";
    };

    const formatDate = (dateString:any) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('es-PE', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    };

    const statusRenderer = (rowData : CouponsInterface) => {
        if (rowData.status) {
            console.debug(rowData.status, 'rowData.status')
            return <span className="badge-active">Activo</span>;
        } else {
            return <span className="badge-inactive">Inactivo</span>;
        }
    };
    const actionButton = (rowData: any) => {
        const { id, name, code, dateExpired, status } = rowData;

        const handleEditClick = () => {
            setSelectedCoupon({ id, name, code, dateExpired, status });
        };

        const handleDeleteClick = () => {
            setSelectedCouponId(id);
        };
        return (
            <div className="table-actions d-flex">
                <Link
                    className="delete-table me-2"
                    onClick={handleEditClick}
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#edit-coupon"
                >
                    <Icon.Edit className='react-feather-custom' />
                </Link>
                <Link
                    className="delete-table"
                    onClick={handleDeleteClick}
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#delete-coupon">
                    <Icon.Trash2 className='react-feather-custom' />
                </Link>
            </div>
        )
    }

    return (
        <>
            <div className="page-wrapper page-settings">
                <div className="content">
                    <div className="content-page-header content-page-headersplit">
                        <h5>Cupones</h5>
                        <div className="list-btn">
                            <ul>
                                <li>
                                    <button
                                        className="btn btn-primary"
                                        type="button"
                                        data-bs-toggle="modal"
                                        data-bs-target="#add-coupon"
                                    >
                                        <i className="fa fa-plus me-2" />
                                        Agregar Cupón
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 ">
                            <div className="table-resposnive">
                                <table className="table datatable">
                                <DataTable
                    paginatorTemplate="RowsPerPageDropdown CurrentPageReport PrevPageLink PageLinks NextPageLink  "
                    currentPageReportTemplate="{first} a {last} de {totalRecords}" value={data} showGridlines tableStyle={{ minWidth: '50rem' }}>
                                    <Column sortable field="name" header="Nombre" ></Column>
                                    <Column sortable field="code" header="Código"></Column>
                                    <Column sortable field="type" header="Tipo"></Column>
                                    <Column sortable field="discount" header="Descuento" ></Column>
                                    <Column sortable field="limit" header="Límite"></Column>
                                    <Column sortable field="dateExpired" header="Fecha Válida" className='valid-data' body={(rowData) => formatDate(rowData.dateExpired)}></Column>
                                    <Column sortable field="packageTypeId" header="Categoría" body={categoryNameBodyTemplate} />
                                    <Column sortable field="status" header="Estado" body={statusRenderer}></Column>
                                    <Column sortable field="action" header="Acción" body={actionButton}></Column>
                                </DataTable>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CouponsModal selectedCoupon={selectedCoupon} onUpdateSuccess={handleUpdateSuccess}/>
            <DeleteCouponsModal  selectedCouponId={selectedCouponId} onDeleteSuccess={handleUpdateSuccess}/>
        </>
    )
}

export default Coupons