import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  set_toggleSidebar_data,
} from '../../../../core/data/redux/action';
import { AppState } from '../../../../core/models/interface';
import { useLocalStorageListener } from './useLocalStorageListener';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';

type props = {
  type: number;
};
const HomeHeader: React.FC<props> = ({ type }) => {
  const [authToken, setAuthToken] = useState(localStorage.getItem('authToken'));
  const toggle_data = useSelector((state: AppState) => state.toggleSidebar);
  const [userName, setUserName] = useState(localStorage.getItem('userName'));
  const routes = all_routes;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toogle = () => {
    dispatch(set_toggleSidebar_data(toggle_data ? false : true));
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('userId');
    localStorage.removeItem('userName');
    localStorage.removeItem('userProfile');
    setAuthToken(null);
    setUserName(null);
    console.log('Sesión cerrada');
  };

  useEffect(() => {
    if (authToken === null) {
      navigate('/');
    }
  }, [authToken, navigate]);

  useLocalStorageListener('authToken', (newValue) => {
    setAuthToken(newValue);
  });

  useLocalStorageListener('userName', (newValue) => {
    setUserName(newValue);
  });

  return (
    <>
    <header>
      <div className={`${type == 4 || type == 1 ? 'container-fluid' : 'container'}`}>
        <nav className="navbar navbar-expand-lg header-nav">
          <div className="navbar-header">
          <Link onClick={toogle} id="mobile_btn" to="#">
                <span className="bar-icon">
                  <span />
                  <span />
                  <span />
                </span>
              </Link>
            <Link to={routes.index} className="navbar-brand logo">
              <ImageWithBasePath
                src="assets/img/logo-lift.svg"
                className="img-fluid"
                alt="Logo"
              />
            </Link>
            <Link to={routes.index} className="navbar-brand logo-small">
              <ImageWithBasePath
                src="assets/img/logo-lift.svg"
                className="img-fluid"
                alt="Logo"
              />
            </Link>
          </div>
          <div className="main-menu-wrapper">
            <div className="menu-header">
              <Link
                onClick={toogle}
                id="menu_close"
                className="menu-close"
                to="#"
              >
                <i className="fas fa-times" />
              </Link>
            </div>
            <ul className="main-nav align-items-lg-center">
              <li className="nav-item">
                <Link target='_blank' to="#">Paquetes</Link>
              </li>
              <li className="nav-item">
                <Link target='_blank' to="#">Suscripciones</Link>
              </li>
            </ul>
          </div>

          <ul className="nav header-navbar-rht">
            {authToken ? (
              <>
                  <li className="nav-item pe-1">
                  <Link className="nav-link btn btn-light" to="#" onClick={handleLogout}>
                    <i className="fas fa-sign-out-alt me-2"></i>Cerrar Sesión
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link btn btn-linear-primary" to={routes.customerDashboard}>
                    <i className="ti ti-user-filled me-2"></i>{userName}
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li className="nav-item pe-1">
                  <Link className="nav-link btn btn-light" to="#" data-bs-toggle="modal" data-bs-target="#login-modal">
                    <i className="ti ti-lock me-2"></i>Ingresar
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link btn btn-linear-primary" to="/" data-bs-toggle="modal" data-bs-target="#register-modal">
                    <i className="ti ti-user-filled me-2"></i>Registrate
                  </Link>
                </li>
              </>
            )}
          </ul>
        </nav>
      </div>
    </header>
  </>
);
};


export default HomeHeader;
