import React, { useEffect } from 'react'
import ImageWithBasePath from '../../../../../core/img/ImageWithBasePath';
import { useNavigate } from 'react-router-dom';

const isAuthenticated = () => {
  return localStorage.getItem('authToken');
};

const PagesAuthHeader = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated()) {
      navigate('/');
    }
  }, [navigate]);

  return (
    <>
      {/* Header */}
      <div className="authentication-header">
        <div className="container">
          <div className="col-md-12">
            <div className="text-center">
              <div className="d-flex justify-content-center align-items-center flex-column">
                <span className="  avatar avatar-m w-auto mt-2">
                <ImageWithBasePath
                  src="assets/lift/brand/logo-lift.svg"
                  alt="logo"
                  className="img-fluid"
                />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Header */}
    </>
  );
}

export default PagesAuthHeader;