import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomersRoutes from './customers.routes';
import NewFooter from '../home/footer/main-footer';
import HomeHeader from '../home/header/main-header';

const isAuthenticated = () => {
  return !!localStorage.getItem('authToken');
};

const Customers = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/');
    }

    // Carga los estilos de acuerdo con la ruta
    window.location.pathname.includes("/perfil")
      ? import("../../../style/css/components.css")
      : import("../../../style/scss/main.scss");
  }, [location.pathname, navigate]);  // Dependemos de `location.pathname` y `navigate`

  return (
    <>
      <HomeHeader type={1}/>
      <CustomersRoutes />
      <NewFooter />
    </>
  );
};

export default Customers;
