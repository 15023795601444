import React from 'react'
import * as Icon from 'react-feather';
import { apiRepository } from '../../../../api/apiRepository';

export interface DeleteCustomersModalProps {
    selectedUserId: number;
    onDeleteSuccess?: () => void;
}

const DeleteCustomersModal: React.FC<DeleteCustomersModalProps> = ({ selectedUserId, onDeleteSuccess }) => {
    const handleConfirmDelete = async () => {
        if (selectedUserId === -1) return;

        try {
            console.log("Intentando eliminar al usuario con ID:", selectedUserId);
            await apiRepository.deleteUser(selectedUserId);
            console.log("Usuario eliminada con éxito");

            if (onDeleteSuccess) {
                onDeleteSuccess();
            }
        } catch (error) {
            console.error("Error al eliminar categoría:", error);
        }
    };

    return (
        <div>
            <div className="modal fade" id="delete-user">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body pt-0">
                            <div className="text-center">
                                <Icon.Trash2 size={45} color='#f05050 ' className="text-danger fs-1" />
                                <div className="mt-4">
                                    <h4>¿Eliminar Usuario?</h4>
                                    <p className="text-muted mb-0">¿Estás seguro de que quieres eliminar esto?</p>
                                </div>
                            </div>
                            <div className="d-flex gap-2 justify-content-center mt-4">
                                <button
                                    type="button"
                                    className="btn w-sm btn-secondary"
                                    data-bs-dismiss="modal"
                                >
                                    Cerrar
                                </button>
                                <button
                                    type="button"
                                    className="btn w-sm btn-danger"
                                    data-bs-dismiss="modal"
                                    onClick={handleConfirmDelete}
                                    >
                                    ¡Sí, Eliminar!
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default DeleteCustomersModal