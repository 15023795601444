export const subscriptionData = [
    {
        id: 1,
        title: "1 Mes",
        features: [
            "Evaluación inicial personalizada",
            "Acceso a entrenamiento grupal",
            "Asesoramiento en nutrición básica"
        ],
        price: "S/840.00",
        per: "por mes",
        description: "Acceso por 30 días",
    },
    {
        id: 2,
        title: "3 Meses",
        features: [
            "Evaluación inicial personalizada",
            "Acceso a entrenamiento grupal",
            "Asesoramiento en nutrición básica"
        ],
        price: "S/2,160.00",
        per: "por 3 meses",
        description: "Acceso por 90 días",
        popular: true
    },
    {
        id: 3,
        title: "6 Meses",
        features: [
            "Evaluación inicial personalizada",
            "Acceso a entrenamiento grupal",
            "Asesoramiento en nutrición básica"
        ],
        price: "S/4,180.00",
        per: "por 6 meses",
        description: "Acceso por 180 días",
    }
];
