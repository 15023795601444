import React from 'react';
import { apiRepository } from '../../../../api/apiRepository';
import * as Icon from 'react-feather';

export interface DeleteCategoriesModalProps {
    selectedCategoryId: number;
    onDeleteSuccess?: () => void;
}

const DeleteCategoriesModal: React.FC<DeleteCategoriesModalProps> = ({ selectedCategoryId, onDeleteSuccess }) => {

    const handleConfirmDelete = async () => {
        if (selectedCategoryId === -1) return;

        try {
            console.log("Intentando eliminar categoría con ID:", selectedCategoryId);
            await apiRepository.deletePackageTypes(selectedCategoryId);
            console.log("Categoría eliminada con éxito");

            if (onDeleteSuccess) {
                onDeleteSuccess();
            }
        } catch (error) {
            console.error("Error al eliminar categoría:", error);
        }
    };

    return (
        <div>
            <div className="modal fade" id="delete-category">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body pt-0">
                            <div className="text-center">
                                <Icon.Trash2 size={45} color='#f05050' className="text-danger fs-1" />
                                <div className="mt-4">
                                    <h4>Eliminar Categoria?</h4>
                                    <p className="text-muted mb-0">¿Estás seguro que quieres eliminar esta categoria?</p>
                                </div>
                            </div>
                            <div className="d-flex gap-2 justify-content-center mt-4">
                                <button
                                    type="button"
                                    className="btn w-sm btn-secondary"
                                    data-bs-dismiss="modal"
                                >
                                    Cancelar
                                </button>
                                <button
                                    type="button"
                                    className="btn w-sm btn-danger"
                                    data-bs-dismiss="modal"
                                    onClick={handleConfirmDelete}
                                >
                                    Sí, Borrar Categoria
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DeleteCategoriesModal;